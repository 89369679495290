import { useDispatch } from 'react-redux';
import { Para } from '../../../../components/atoms/typography/typography';
import { convertISOToDate, convertTime } from '../../../../utils/utils';
import { retryCallsAction } from '../../../../redux/states/msme/msme.actions';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ArrowClockwise } from 'phosphor-react';
import { StandardBtn } from '../../../../components/molecules/buttons/buttons';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { displayCallStatus } from '../../../../utils/ui';
import AttemptCard from '../../../../components/organism/attempt-card/attemptCard';

export default function OffcanvasRight({
  selectedCall,
  updateSelectedCall,
  campaignStatus,
  campaignType
}: any) {
  const dispatch: any = useDispatch();
  const ISOFormat = new Date(selectedCall.lastAttemptedOn || '');
  const time = convertTime(ISOFormat);
  let lastAttemptedDate = convertISOToDate(ISOFormat);
  const [playRecording, setPlayRecording] = useState(false);
  const { campaignId } = useParams();
  const isLoading = useSelector(getLoaderValue);
  const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);
  const [currentAudioRef, setCurrentAudioRef] = useState<HTMLAudioElement | null>(null);

  const playAudio = (audioUrl: string | null, audioRef: HTMLAudioElement | null) => {
    if (currentAudioRef && currentAudioRef !== audioRef) {
      currentAudioRef.pause();
    }
    setCurrentAudioUrl(audioUrl);
    setCurrentAudioRef(audioRef);
  };

  useEffect(() => {
    setPlayRecording(true);
  }, [selectedCall]);

  async function retry() {
    dispatch({ type: LOADING_ON });
    const data = {
      campaignId: campaignId,
      userMobileNumbers: [selectedCall?.userPhoneNumber]
    };
    const res = await dispatch(retryCallsAction(data));
    if (res.status == 200) {
      dispatch({ type: LOADING_OFF });
      toast.success('Retry action initiated');
    } else {
      dispatch({ type: LOADING_OFF });

      toast.success('Failed');
    }
  }

  return (
    <div
      className="offcanvas offcanvas-end sidebar-size"
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      data-bs-scroll="false"
      data-bs-backdrop="false"
      style={{ background: 'var(--backgroundMain)' }}
    >
      <div className="offcanvas-header pb-0">
        <div className="m-2">
          <h5 className="offcanvas-title fw-600 d-flex" id="offcanvasRightLabel">
            Status :
            <div className="d-flex flex-column justify-content-center">
              <div className="ms-2">{displayCallStatus(selectedCall?.status)}</div>
            </div>
          </h5>
          <Para>
            <>Name : {selectedCall?.userName}</>
          </Para>
          <Para>
            <>Contact : {selectedCall?.userPhoneNumber}</>
          </Para>
          <Para>
            <>Last Called : {lastAttemptedDate + ' | ' + time}</>
          </Para>
        </div>

        <div className="d-flex p-0">
          {campaignStatus && campaignType != 'INCOMING' && (
            <StandardBtn className=" mx-2 my-5" isLoading={isLoading} handleBtnClick={retry}>
              <>
                <ArrowClockwise size={16} weight="bold" className="me-2" />
                <Para>Retry</Para>
              </>
            </StandardBtn>
          )}

          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setPlayRecording(false);
              updateSelectedCall(false);
            }}
          ></button>
        </div>
      </div>
      <div className="offcanvas-body">
        {selectedCall &&
          selectedCall?.calls?.map((item: any, index: number) => (
            <AttemptCard
              item={item}
              key={index}
              attempt={index + 1}
              playRecording={playRecording}
              playAudios={playAudio}
              currentAudioUrl={currentAudioUrl}
            />
          ))}
      </div>
    </div>
  );
}
