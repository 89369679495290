import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CallDetailsTable from '../call-details-table/callDetailsTable';

import './styles.css';
import {
  getCampaignCallsAction,
  getCampaignConversionAction,
  getCampaignSummaryAction,
  getSpecificCampaignDetailAction
} from '../../../../redux/states/msme/msme.actions';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import SpecificCampaignTable from '../specific-campaign-table/specificCampaignTable';
import { getSpecificCampaignDetail } from '../../../../redux/services/api/apiConstants';
import { SecondaryButton } from '../../../../components/molecules/buttons/buttons';
import { SkeletonLoader } from '../../../../components/organism/skeleton-loader/skeletonLoader';
import { LoaderType } from '../../../../models/ui.enum';
import { FontWeight, Heading2, Para } from '../../../../components/atoms/typography/typography';
import { CallDetailDto } from '../../../../redux/states/msme/msme.dto';

export const CampaignDetails = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const today = new Date();
  const current = today.toISOString().substring(0, 10);

  const [barData, setBarData] = useState('monthly');
  const [toDate, setToDate] = useState(current);

  const { campaignId } = useParams();

  const sixDaysAgo = new Date(today);
  sixDaysAgo.setDate(today.getDate() - 6);

  const formattedDate = sixDaysAgo.toISOString().split('T')[0];
  const [fromDate, setFromDate] = useState(formattedDate);
  const [callListData, setCallListData] = useState<CallDetailDto[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    dispatch(getSpecificCampaignDetailAction(campaignId || ''));
    async function getCalls() {
      const res = await dispatch(getCampaignCallsAction(campaignId || '', 1));
      setCallListData(res?.data?.calls);
      setTotalPages(res?.data?.totalPage);
    }
    getCalls();
  }, []);

  const { data: specificCampaignDetails, loading: loadingDetail } = useSelector(
    (state: any) => state?.msme?.specificCampaignDetail
  );

  useEffect(() => {
    dispatch(getCampaignConversionAction(campaignId || '', barData));
  }, [barData]);

  useEffect(() => {
    dispatch(getCampaignSummaryAction(campaignId || '', toDate, fromDate));
  }, [fromDate, toDate]);

  const { data: campaignConversion } = useSelector((state: any) => state?.msme?.campaignConversion);

  const { data: campaignSummary } = useSelector((state: any) => state?.msme?.campaignSummary);

  const conversionData = campaignConversion?.map((conv: any) => ({
    name: conv.date,
    attempted: Number(conv.attempted),
    connected: Number(conv.connected),
    '30s': Number(conv.t30s),
    '60s': Number(conv.t60s),
    '90s': Number(conv.t90s)
  }));

  function getRandomColor() {
    const color = Math.floor(Math.random() * 0xffffff).toString(16);
    return '#' + color.padStart(6, '0');
  }

  const data = Object.keys(campaignSummary).map((key: any) => ({
    name: campaignSummary[key].name,
    value: campaignSummary[key].count,
    fill: getRandomColor()
  }));

  const COLORS = ['#7f56d9', '#aa83e6', '#bca7ff', '#d5bdf0'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderColorfulLegendText = (value: string) => {
    return <span style={{ color: 'black' }}>{value}</span>;
  };

  return (
    <Fragment>
      <div className="px-4">
        {/* <div className="overlay"></div> */}
        <div className="container">
          {loadingDetail ? (
            <SkeletonLoader cardType={LoaderType.TILE} numTiles={2} />
          ) : (
            <div className="chart-card px-4  my-5">
              <SpecificCampaignTable
                specificCampaign={specificCampaignDetails}
                campaignId={campaignId}
              />
            </div>
          )}
          {callListData.length != 0 ? (
            <div className="row">
              <div className="col-lg-6">
                <div className="chart-card">
                  <div className="d-flex flex-row-reverse justify-content-center align-items-center charts-data py-4">
                    <SecondaryButton
                      className={`${barData === 'daily' ? 'active' : ''} ms-4`}
                      handleBtnClick={() => {
                        setBarData('daily');
                      }}
                    >
                      <Para fontWeight={FontWeight.MEDIUM}>Daily</Para>
                    </SecondaryButton>
                    <SecondaryButton
                      className={`${barData === 'weekly' ? 'active' : ''} ms-4`}
                      handleBtnClick={() => {
                        setBarData('weekly');
                      }}
                    >
                      <Para fontWeight={FontWeight.MEDIUM}>Weekly</Para>
                    </SecondaryButton>
                    <SecondaryButton
                      className={`${barData === 'monthly' ? 'active' : ''}`}
                      handleBtnClick={() => {
                        setBarData('monthly');
                      }}
                    >
                      <Para fontWeight={FontWeight.MEDIUM}>Monthly</Para>
                    </SecondaryButton>
                  </div>
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart
                      width={700}
                      height={500}
                      data={conversionData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 0,
                        bottom: 0
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" angle={-45} tickMargin={15} textAnchor="end" dy={-8} />
                      <YAxis
                        label={{
                          value: 'Number of calls',
                          angle: -90,
                          position: 'center'
                        }}
                      />
                      <Tooltip />
                      <Legend
                        layout="horizontal"
                        align="center"
                        verticalAlign="bottom"
                        wrapperStyle={{ paddingTop: '80px' }}
                        height={110}
                      />
                      {/* <Bar dataKey="attempted" fill="#ea4335" radius={[5, 5, 0, 0]} /> */}
                      <Bar dataKey="connected" fill="#4285f4" radius={[5, 5, 0, 0]} />
                      <Bar dataKey="30s" fill="#fbbc04" radius={[5, 5, 0, 0]} />
                      <Bar dataKey="60s" fill="#34a853" radius={[5, 5, 0, 0]} />
                      <Bar dataKey="90s" fill="#7f56d9" radius={[5, 5, 0, 0]} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="chart-card mt-lg-0 mt-4">
                  <div className="pt-3 px-4">
                    <div className="row">
                      <div className="col-md-6">
                        <PrimaryTextfield
                          type="date"
                          value={fromDate}
                          onChange={(e) => {
                            setFromDate(e);
                          }}
                          fieldName="fromDate"
                          label="From"
                          preventDefault
                        ></PrimaryTextfield>
                      </div>
                      <div className="col-md-6">
                        <PrimaryTextfield
                          type="date"
                          value={toDate}
                          onChange={(e) => {
                            setToDate(e);
                          }}
                          fieldName="toDate"
                          label="To"
                          preventDefault
                        ></PrimaryTextfield>
                      </div>
                    </div>
                  </div>
                  <div className="pb-4">
                    <div className="text-center">
                      {data.find((key: any) => key.value !== 0) ? (
                        <ResponsiveContainer width="100%" height={500}>
                          <PieChart>
                            <Legend
                              layout="horizontal"
                              align="center"
                              verticalAlign="bottom"
                              formatter={renderColorfulLegendText}
                            />
                            <Tooltip />
                            <Pie
                              data={data}
                              label={renderCustomizedLabel}
                              innerRadius={70}
                              outerRadius={130}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {data.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                  style={{ outline: 'none' }}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      ) : (
                        <>
                          <div className=" flex-column d-flex align-items-center mt-4 justify-content-center text-center">
                            <div className="empty-doughnut mt-5">
                              <Heading2 className="mt-5 pt-4">No data</Heading2>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <CallDetailsTable
            campaignId={campaignId || ''}
            callListData={callListData}
            totalPages={totalPages}
            campaignName={specificCampaignDetails.name || ''}
            campaignStatus={specificCampaignDetails?.active}
            campaignType={specificCampaignDetails?.type}
          />
        </div>
      </div>
    </Fragment>
  );
};
