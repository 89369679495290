import React, { useEffect } from 'react';
import { getNameInitials, onLogout } from '../../utils/utils';
import { Caption, FontWeight, Heading1, Heading3 } from '../atoms/typography/typography';
import { useNavigate } from 'react-router-dom';
import Logo from './../../assets/logo.svg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CaretDown } from 'phosphor-react';
import './styles.css';
import { loadData } from '../../redux/services/handleData/localStorage';
import { LOGO_BASE_URL } from '../../utils/config';
import { getMsmeUserAction } from '../../redux/states/msme/msme.actions';

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { data: user } = useSelector((state: any) => state?.msme?.msmeUser);
  const logoUrl = `${LOGO_BASE_URL}/${user?.msmeDetails?.logo}`;

  useEffect(() => {
    if (loadData('token')) {
      dispatch(getMsmeUserAction());
    }
  }, []);

  function handleAuth() {
    if (loadData('token')) {
      return (
        <>
          <div className="dropdown">
            <div
              className=" d-flex align-items-center"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {user?.msmeDetails?.logo ? (
                <img src={logoUrl} alt="User Logo" className="logo me-2" />
              ) : (
                <div className="name-initials-avatar me-2">{getNameInitials(user?.name ?? '')}</div>
              )}
              <div className="me-2">
                <Heading3 fontWeight={FontWeight.MEDIUM} className="text-capitalize">
                  {user?.name}
                </Heading3>
                <Caption textColor="var(--textGray)">{user?.email}</Caption>
              </div>
              <CaretDown size={18} weight="fill" color="var(--textGray)" />
            </div>

            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item cursor-na disabled" href="#">
                  View Profile
                </a>
              </li>
              <li
                onClick={() => {
                  navigate('/');
                  onLogout();
                }}
              >
                <a className="dropdown-item text-danger" href="#">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
  return (
    <nav className="navbar p-0 my-2 navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <Heading1 className="navbar-brand">
          <img src={Logo} onClick={() => navigate('/')} role="button" />
        </Heading1>

        <div id="navbarSupportedContent">
          {loadData('token') ? (
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0"></ul>
          ) : (
            <div className="mx-auto"></div>
          )}
          <form>{handleAuth()}</form>
        </div>
      </div>
    </nav>
  );
}
