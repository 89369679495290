import React, { useEffect, useState } from 'react';
import { PrimaryTextfield } from '../../components/molecules/textfield/textfield';
import { LinkButton, PrimaryButton, StandardBtn } from '../../components/molecules/buttons/buttons';
import { Heading1, Heading2, Para } from '../../components/atoms/typography/typography';
import { ButtonType } from '../../models/ui.enum';
import { useNavigate } from 'react-router';
import { signInUser, verifyEmail } from '../../firebase';
import { useDispatch } from 'react-redux';
import { saveData } from '../../redux/services/handleData/localStorage';
import { AlertModal } from '../../components/molecules/modal/modal';
import NoInternet from '../../assets/no-internet.svg';
import Check from '../../assets/check.png';
import { Eye, EyeSlash } from 'phosphor-react';
import { LOADING_OFF, LOADING_ON } from '../../redux/states/ui/ui.actions';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../redux/states/ui/ui.services';
import { showAlert } from '../../utils/utils';
import onlyLogo from '../../assets/OnlyLogo.svg';
import './styles.css';

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isUnAuthorizeUser, setUnAuthorizeUser] = useState<boolean>(false);
  const [isVerificationLinkSent, setVerificationLinkStatus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const isLoading = useSelector(getLoaderValue);

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    const oobCode = queryString.get('oobCode');
    const mode = queryString.get('mode');

    if (oobCode) {
      if (mode === 'resetPassword') {
        navigate('/reset-password', { state: { oobCode: oobCode } });
      } else {
        verifyEmail(oobCode);
      }
    }
  }, []);

  const ERROR_CODES = {
    USER_NOT_FOUND: 'auth/user-not-found',
    WRONG_PASSWORD: 'auth/wrong-password',
    TOO_MANY_REQUESTS: 'auth/too-many-requests'
  };

  function handleSignInError(errorCode: string) {
    switch (errorCode) {
      case ERROR_CODES.USER_NOT_FOUND:
        setUnAuthorizeUser(true);
        break;
      case ERROR_CODES.WRONG_PASSWORD:
        showAlert(dispatch, 'Wrong Password', 'Please enter the correct password');
        break;
      case ERROR_CODES.TOO_MANY_REQUESTS:
        showAlert(dispatch, 'Too many attempts. Try later');
        break;
      default:
        showAlert(dispatch, 'An unexpected error occurred');
    }
  }

  async function handleOnSubmit() {
    dispatch({ type: LOADING_ON });
    try {
      const res: any = await signInUser(email, password);

      dispatch({ type: LOADING_OFF });
      if (res?.code) {
        handleSignInError(res.code);
        return;
      }

      if (!res?.emailVerified) {
        setVerificationLinkStatus(true);
        return;
      }

      // dispatch({ type: LOADING_ON });
      // const result = await dispatch(validateAdminAction({ email: res?.email }));
      // dispatch({ type: LOADING_OFF });

      // if (result?.data?.data?.inActive !== null && !result?.data?.data.inActive) {
      saveData('token', res?.accessToken);
      window.location.reload();
      return;
      // }
      // showAlert(dispatch, 'Invalid User');
    } catch (e: any) {
      showAlert(dispatch, 'Error occurred');
      dispatch({ type: LOADING_OFF });
    }
  }

  function togglePasswordView() {
    setShowPassword(!showPassword);
  }

  return (
    <div className="position-relative">
      <div className="row justify-content-center text-center mt-5 p-4 mx-0">
        <div className="col-lg-4 login-card p-4">
          <div className="main-content-wrapper">
            <img src={onlyLogo} className="pb-4" alt="" />
            <Heading2 className="mb-2">Log in to your account</Heading2>
            <Para textColor="var(--textGray)" className="mb-5">
              Welcome back! Please enter your details.
            </Para>
            <br />
            <form
              onSubmit={(e) => {
                e?.preventDefault();
                handleOnSubmit();
              }}
            >
              <div className="text-start">
                <PrimaryTextfield
                  onChange={(value) => {
                    setEmail(value);
                  }}
                  label="Email"
                  placeholder="Enter your email"
                  value={email}
                  fieldName="email"
                  type="email"
                  required
                />

                <PrimaryTextfield
                  onChange={(value) => {
                    setPassword(value);
                  }}
                  label="Password"
                  placeholder="Password"
                  value={password}
                  fieldName="password"
                  type={showPassword ? 'text' : 'password'}
                  required
                  smallBottomMargin
                  suffixIcon={showPassword ? <Eye size={24} /> : <EyeSlash size={24} />}
                  onSuffixIconClick={togglePasswordView}
                />
                <div className="d-flex justify-content-end mb-3">
                  <LinkButton handleBtnClick={() => navigate('/forgot-password')}>
                    Forgot Password?
                  </LinkButton>
                </div>
                <PrimaryButton
                  className="w-100 mt-4"
                  type={ButtonType.SUBMIT}
                  handleBtnClick={() => {}}
                  isLoading={isLoading}
                  loadingText="Please wait"
                >
                  Sign In
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>

        <AlertModal
          active={isUnAuthorizeUser}
          primaryBtnTitle={'Create Account'}
          onPrimaryBtnClick={() => {
            navigate('/signup');
          }}
          onClose={() => {
            setUnAuthorizeUser(false);
          }}
        >
          <div className="text-center">
            <img src={NoInternet} className="mb-3" />
            <Heading2 className="mb-2">Create Account</Heading2>
            <Para textColor="var(--textGray)" className="mb-4">
              You need to create new account to access voizo
            </Para>
          </div>
        </AlertModal>

        <AlertModal
          active={isVerificationLinkSent}
          primaryBtnTitle={'Ok'}
          onPrimaryBtnClick={() => {
            setVerificationLinkStatus(false);
          }}
          onClose={() => {
            setVerificationLinkStatus(false);
          }}
        >
          <div className="text-center">
            <img src={Check} className="mb-3" />
            <Heading2 className="mb-2">Email Verification Link Sent</Heading2>
            <Para textColor="var(--textGray)" className="mb-4">
              Please check your email and follow the instructions to verify email
            </Para>
          </div>
        </AlertModal>
      </div>
    </div>
  );
}
