import React, { useEffect, useState } from 'react';
import { Heading2, Para } from '../../../../components/atoms/typography/typography';
import { PrimaryButton } from '../../../../components/molecules/buttons/buttons';
import { ButtonType } from '../../../../models/ui.enum';
import {
  getInstructionsAction,
  updateInstructionsAction
} from '../../../../redux/states/msme/msme.actions';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { toast } from 'react-toastify';
import { InfoModal } from '../../../../components/molecules/modal/modal';
import { WarningCircle } from 'phosphor-react';
import { replaceDoubleQuotesWithSingleQuotes, showAlert } from '../../../../utils/utils';

export default function EditDetails() {
  const [instruction, setInstruction] = useState('');
  const [summaryQuery, setSummaryQuery] = useState('');
  const [extractionQuery, setExtractionQuery] = useState('');
  const [gptFunction, setGptFunction] = useState<[]>([]);
  const [gptFunctionData, setGptFunctionData] = useState<string>('');

  const isLoading = useSelector(getLoaderValue);
  const { campaignId } = useParams();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userMetaData } = state;

  const { data: instructions } = useSelector((states: any) => states?.msme?.instructions);
  const { data: adminEmail } = useSelector((states: any) => states?.msme?.msmeUser);

  async function getInstructions() {
    if (adminEmail?.email?.endsWith('@voizo.ai')) {
      const res = await dispatch(getInstructionsAction(campaignId || ''));
      setInstruction(res?.data?.instruction);
      setSummaryQuery(res?.data?.summaryQuery);
      setExtractionQuery(res?.data?.extractionQuery);
      setGptFunction(res?.data?.gptFunctions);
    }
  }

  function formatData(data: string) {
    return data.replace(/\\n/g, '\n').replace(/\\/g, ' ');
  }

  useEffect(() => {
    getInstructions();
  }, [adminEmail]);

  useEffect(() => {
    setGptFunctionData(JSON.stringify(gptFunction, null, 4));
  }, [gptFunction]);

  async function updateInstructions() {
    dispatch({ type: LOADING_ON });
    try {
      const data = {
        id: campaignId,
        instruction: replaceDoubleQuotesWithSingleQuotes(instruction),
        extractionQuery: extractionQuery,
        summaryQuery: summaryQuery,
        gptFunctions: JSON.parse(gptFunctionData)
      };
      const res = await dispatch(updateInstructionsAction(data));
      dispatch({ type: LOADING_OFF });
      if (res.status == 200) {
        toast.success('Update Successful');
        navigate(-1);
      } else {
        showAlert(dispatch, res?.message);
      }
    } catch (error) {
      dispatch({ type: LOADING_OFF });
      console.log(error);
    }
  }

  return (
    <div>
      {adminEmail?.email?.endsWith('@voizo.ai') ? (
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className={`edit-card mt-5 p-4`}>
              <Heading2 className="mb-3">Summary Query : </Heading2>
              <textarea
                className="text-area"
                rows={15}
                value={summaryQuery}
                onChange={(e: any) => {
                  setSummaryQuery(e.target.value);
                }}
              ></textarea>
              <Heading2 className="mb-3 mt-4">Extraction Query : </Heading2>
              <textarea
                className="text-area"
                rows={5}
                value={extractionQuery}
                onChange={(e: any) => {
                  setExtractionQuery(e.target.value);
                }}
              ></textarea>
              <Heading2 className="mb-3 mt-4">Instructions : </Heading2>
              <textarea
                className="text-area"
                rows={15}
                value={formatData(instruction)}
                onChange={(e: any) => {
                  setInstruction(e.target.value);
                }}
              ></textarea>
              <Heading2 className="mb-3 mt-4">GPT Functions : </Heading2>
              <textarea
                className="text-area"
                rows={15}
                value={gptFunctionData}
                onChange={(e: any) => {
                  try {
                    const value = e.target.value;
                    setGptFunctionData(value);
                  } catch (error) {
                    console.error(error);
                  }
                }}
                style={{ whiteSpace: 'pre-wrap' }}
              ></textarea>
              {userMetaData.length != 0 && (
                <div>
                  <Heading2 className="mb-3 mt-4">User MetaData : </Heading2>
                  {userMetaData.map((field: any, index: any) => (
                    <div key={index} className="d-flex my-4 justify-content-between">
                      <input
                        type="text"
                        placeholder="Label"
                        value={field.label}
                        className="form-control primary-input me-2"
                        style={{ width: '40%' }}
                        onChange={() => {}}
                        disabled
                      />

                      <input
                        type="text"
                        placeholder="Value"
                        value={field.value}
                        className="form-control primary-input me-2"
                        style={{ width: '40%' }}
                        onChange={() => {}}
                        disabled
                      />
                    </div>
                  ))}
                </div>
              )}
              <PrimaryButton
                className="w-100 mt-4"
                handleBtnClick={updateInstructions}
                type={ButtonType.SUBMIT}
                isLoading={isLoading}
                loadingText="Please wait"
              >
                Update
              </PrimaryButton>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
