import React from 'react';
import { Para } from '../../atoms/typography/typography';
import { ButtonType } from '../../../models/ui.enum';
import './style.css';

export interface ButtonProps {
  className?: string;
  handleBtnClick: () => any;
  isDisabled?: boolean;
  type?: ButtonType;
  id?: string;
  preventDefault?: boolean;
  stopPropagation?: boolean;
  children: string | JSX.Element;
  img?: string;
  icon?: JSX.Element;
  btnTextColor?: string;
  btnBackgroundColor?: string;
  btnBorderColor?: string;
  isRounded?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  btnSize?: string;
}

type BaseButtonProps = Omit<ButtonProps, 'children'> & {
  children: string | JSX.Element;
};

function BaseButton(props: BaseButtonProps) {
  function onClick(e: any) {
    if (props.preventDefault) {
      e?.preventDefault();
    }
    if (props.stopPropagation) {
      e?.stopPropagation();
    }
    if (!props.isLoading) {
      props.handleBtnClick();
    }
  }
  return (
    <button
      className={props.className}
      onClick={onClick}
      disabled={props.isDisabled || props.isLoading}
      type={props.type || ButtonType.BUTTON}
      id={props.id}
      style={{
        color: props.btnTextColor,
        backgroundColor: props.btnBackgroundColor,
        borderRadius: props.isRounded ? '50px' : '',
        borderColor: props.btnBorderColor
      }}
    >
      {props.isLoading ? props.loadingText ?? 'Loading...' : props.children}
    </button>
  );
}

export function PrimaryButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`btn primary btn-full d-flex align-items-center justify-content-center ${props.className}`}
    />
  );
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`btn secondary-btn btn-full d-flex align-items-center justify-content-center ${props.className}`}
    />
  );
}

export function StandardBtn(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`btn btn-${props.btnSize} btn-full d-flex align-items-center fs-6 justify-content-center ${props.className} standard-btn`}
    />
  );
}

export function LinkButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`btn btn-link btn-full d-flex align-items-center justify-content-center ${props.className}`}
    />
  );
}
