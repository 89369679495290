export const GET_MSME_CAMPAIGNS = 'GET_MSME_CAMPAIGNS';
export const GET_MSME_CAMPAIGNS_REQUEST = 'GET_MSME_CAMPAIGNS_REQUEST';
export const GET_MSME_CAMPAIGNS_SUCCESS = 'GET_MSME_CAMPAIGNS_SUCCESS';
export const GET_MSME_CAMPAIGNS_FAILED = 'GET_MSME_CAMPAIGNS_FAILED';

export const GET_MSME_USER = 'GET_MSME_USER';
export const GET_MSME_USER_REQUEST = 'GET_MSME_USER_REQUEST';
export const GET_MSME_USER_SUCCESS = 'GET_MSME_USER_SUCCESS';
export const GET_MSME_USER_FAILED = 'GET_MSME_USER_FAILED';

export const GET_CAMPAIGN_CALLS = 'GET_CAMPAIGN_CALLS';
export const GET_CAMPAIGN_CALLS_REQUEST = 'GET_CAMPAIGN_CALLS_REQUEST';
export const GET_CAMPAIGN_CALLS_SUCCESS = 'GET_CAMPAIGN_CALLS_SUCCESS';
export const GET_CAMPAIGN_CALLS_FAILED = 'GET_CAMPAIGN_CALLS_FAILED';

export const GET_CAMPAIGN_SUMMARY = 'GET_CAMPAIGN_SUMMARY';
export const GET_CAMPAIGN_SUMMARY_REQUEST = 'GET_CAMPAIGN_SUMMARY_REQUEST';
export const GET_CAMPAIGN_SUMMARY_SUCCESS = 'GET_CAMPAIGN_SUMMARY_SUCCESS';
export const GET_CAMPAIGN_SUMMARY_FAILED = 'GET_CAMPAIGN_SUMMARY_FAILED';

export const GET_CAMPAIGN_CONVERSION = 'GET_CAMPAIGN_CONVERSION';
export const GET_CAMPAIGN_CONVERSION_REQUEST = 'GET_CAMPAIGN_CONVERSION_REQUEST';
export const GET_CAMPAIGN_CONVERSION_SUCCESS = 'GET_CAMPAIGN_CONVERSION_SUCCESS';
export const GET_CAMPAIGN_CONVERSION_FAILED = 'GET_CAMPAIGN_CONVERSION_FAILED';

export const GET_SPECIFIC_CAMPAIGN_DETAIL = 'GET_SPECIFIC_CAMPAIGN_DETAIL';
export const GET_SPECIFIC_CAMPAIGN_DETAIL_REQUEST = 'GET_SPECIFIC_CAMPAIGN_DETAIL_REQUEST';
export const GET_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS = 'GET_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS';
export const GET_SPECIFIC_CAMPAIGN_DETAIL_FAILED = 'GET_SPECIFIC_CAMPAIGN_DETAIL_FAILED';

export const BULK_UPLOAD = 'BULK_UPLOAD';
export const BULK_UPLOAD_REQUEST = 'BULK_UPLOAD_REQUEST';
export const BULK_UPLOAD_SUCCESS = 'BULK_UPLOAD_SUCCESS';
export const BULK_UPLOAD_FAILED = 'BULK_UPLOAD_FAILED';

export const UPDATE_SPECIFIC_CAMPAIGN_DETAIL = 'UPDATE_SPECIFIC_CAMPAIGN_DETAIL';
export const UPDATE_SPECIFIC_CAMPAIGN_DETAIL_REQUEST = 'UPDATE_SPECIFIC_CAMPAIGN_DETAIL_REQUEST';
export const UPDATE_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS = 'UPDATE_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS';
export const UPDATE_SPECIFIC_CAMPAIGN_DETAIL_FAILED = 'UPDATE_SPECIFIC_CAMPAIGN_DETAIL_FAILED';

export const GET_CALL_RECORDING = 'GET_CALL_RECORDING';
export const GET_CALL_RECORDING_REQUEST = 'GET_CALL_RECORDING_REQUEST';
export const GET_CALL_RECORDING_SUCCESS = 'GET_CALL_RECORDING_SUCCESS';
export const GET_CALL_RECORDING_FAILED = 'GET_CALL_RECORDING_FAILED';

export const ADD_SINGLE_CALL_NUMBER = 'ADD_SINGLE_CALL_NUMBER';
export const ADD_SINGLE_CALL_NUMBER_REQUEST = 'ADD_SINGLE_CALL_NUMBER_REQUEST';
export const ADD_SINGLE_CALL_NUMBER_SUCCESS = 'ADD_SINGLE_CALL_NUMBER_SUCCESS';
export const ADD_SINGLE_CALL_NUMBER_FAILED = 'ADD_SINGLE_CALL_NUMBER_FAILED';

export const GET_INSTRUCTIONS = 'GET_INSTRUCTIONS';
export const GET_INSTRUCTIONS_REQUEST = 'GET_INSTRUCTIONS_REQUEST';
export const GET_INSTRUCTIONS_SUCCESS = 'GET_INSTRUCTIONS_SUCCESS';
export const GET_INSTRUCTIONS_FAILED = 'GET_INSTRUCTIONS_FAILED';

export const UPDATE_INSTRUCTIONS = 'UPDATE_INSTRUCTIONS';
export const UPDATE_INSTRUCTIONS_REQUEST = 'UPDATE_INSTRUCTIONS_REQUEST';
export const UPDATE_INSTRUCTIONS_SUCCESS = 'UPDATE_INSTRUCTIONS_SUCCESS';
export const UPDATE_INSTRUCTIONS_FAILED = 'UPDATE_INSTRUCTIONS_FAILED';

export const RETRY_CALLS = 'RETRY_CALLS';
export const RETRY_CALLS_REQUEST = 'RETRY_CALLS_REQUEST';
export const RETRY_CALLS_SUCCESS = 'RETRY_CALLS_SUCCESS';
export const RETRY_CALLS_FAILED = 'RETRY_CALLS_FAILED';

export const BULK_CALL_SCHEDULE = 'BULK_CALL_SCHEDULE';
export const BULK_CALL_SCHEDULE_REQUEST = 'BULK_CALL_SCHEDULE_REQUEST';
export const BULK_CALL_SCHEDULE_SUCCESS = 'BULK_CALL_SCHEDULE_SUCCESS';
export const BULK_CALL_SCHEDULE_FAILED = 'BULK_CALL_SCHEDULE_FAILED';

export const EXPORT_CALL_DETAILS = 'EXPORT_CALL_DETAILS';
export const EXPORT_CALL_DETAILS_REQUEST = 'EXPORT_CALL_DETAILS_REQUEST';
export const EXPORT_CALL_DETAILS_SUCCESS = 'EXPORT_CALL_DETAILS_SUCCESS';
export const EXPORT_CALL_DETAILS_FAILED = 'EXPORT_CALL_DETAILS_FAILED';

export const SAMPLE_BULK_UPLOAD_DATA = 'SAMPLE_BULK_UPLOAD_DATA';
export const SAMPLE_BULK_UPLOAD_DATA_REQUEST = 'SAMPLE_BULK_UPLOAD_DATA_REQUEST';
export const SAMPLE_BULK_UPLOAD_DATA_SUCCESS = 'SAMPLE_BULK_UPLOAD_DATA_SUCCESS';
export const SAMPLE_BULK_UPLOAD_DATA_FAILED = 'SAMPLE_BULK_UPLOAD_DATA_FAILED';

export const CREATE_MSME_CAMPAIGN = 'CREATE_MSME_CAMPAIGN';
export const CREATE_MSME_CAMPAIGN_REQUEST = 'CREATE_MSME_CAMPAIGN_REQUEST';
export const CREATE_MSME_CAMPAIGN_SUCCESS = 'CREATE_MSME_CAMPAIGN_SUCCESS';
export const CREATE_MSME_CAMPAIGN_FAILED = 'CREATE_MSME_CAMPAIGN_FAILED';

export const GET_SAMPLE_AUDIO = 'GET_SAMPLE_AUDIO';
export const GET_SAMPLE_AUDIO_REQUEST = 'GET_SAMPLE_AUDIO_REQUEST';
export const GET_SAMPLE_AUDIO_SUCCESS = 'GET_SAMPLE_AUDIO_SUCCESS';
export const GET_SAMPLE_AUDIO_FAILED = 'GET_SAMPLE_AUDIO_FAILED';

export const GET_MODEL_CONFIGURATIONS = 'GET_MODEL_CONFIGURATIONS';
export const GET_MODEL_CONFIGURATIONS_REQUEST = 'GET_MODEL_CONFIGURATIONS_REQUEST';
export const GET_MODEL_CONFIGURATIONS_SUCCESS = 'GET_MODEL_CONFIGURATIONS_SUCCESS';
export const GET_MODEL_CONFIGURATIONS_FAILED = 'GET_MODEL_CONFIGURATIONS_FAILED';
