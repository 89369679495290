// export const baseUrl = 'https://reqres.in/api';

// msme

export const getMsmeUser = 'api/msme/admin';

export const getMsmeCampaigns = 'api/msme/campaign';

export const getCampaignCalls = 'api/msme/campaign/call-details';

export const getCampaignSummary = 'api/msme/campaign/summary';

export const getCampaignConversion = 'api/msme/campaign/conversion';

export const bulkUpload = 'api/msme/campaign/bulk-upload';

export const getSpecificCampaignDetail = 'api/msme/campaign/details';

export const updateSpecificCampaignDetail = 'api/msme/campaign';

export const getCallRecording = 'api/msme/campaign/call-recording';

export const postSingleNumber = 'api/msme/campaign/call-upload';

export const getInstructions = 'api/msme/campaign/instruction';

export const updateInstructions = 'api/msme/campaign/instruction';

export const retryCalls = 'api/msme/campaign/call-reschedule';

export const bulkCallSchedule = 'api/external/bulkcallschedule';

export const exportCallDetailsList = 'api/msme/export-call-details';

export const sampleBulkUploadData = 'api/msme/bulk-upload/sample-file';

export const createMsmeCampaign = 'api/msme/campaign';

export const sampleAudio = 'api/msme/test-voice';

export const getModelConfigurations = 'api/configuration';
