import { useEffect, useRef, useState } from 'react';
import { PrimaryButton } from '../../../../components/molecules/buttons/buttons';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import { ButtonType } from '../../../../models/ui.enum';
import { Heading2, Heading3, Para } from '../../../../components/atoms/typography/typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getInstructionsAction,
  getModelConfigurationsAction,
  sampleAudioDataAction,
  updateInstructionsAction,
  updateSpecificCampaignDetailAction
} from '../../../../redux/states/msme/msme.actions';
import { toast } from 'react-toastify';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import './styles.css';
import VoiceWoman from './../../../../assets/women-img.jpg';
import Woman2 from './../../../../assets/women-2.jpg';

import { Play, PlayCircle } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import SelectInput from '../../../../components/organism/select-input/selectInput';
import { base64toBlob, showAlert } from '../../../../utils/utils';

export default function EditCampaignDetails() {
  const { state } = useLocation();
  const {
    msmeCampaignId,
    msmeName,
    greetingMessagePrev,
    statusPrev,
    dailyLimitPrev,
    ttsModel,
    llmModelData,
    sttModelData
  } = state;
  const [greetingMessage, setGreetingMessage] = useState<string>(greetingMessagePrev);
  const [dailyLimit, setDailyLimit] = useState(dailyLimitPrev);
  const [active, setActive] = useState<boolean>(statusPrev);
  const [ssmlConfigsSpeed, setSsmlConfigsSpeed] = useState<string>(ttsModel?.ssmlConfigs?.speed);
  const [ttsVendor, setTtsVendor] = useState<string>(ttsModel.vendor);
  const [selectedTTSModelOption, setSelectedTTSModelOption] = useState(ttsModel?.model);
  const [speechText, setSpeeechText] = useState<string>('');
  const [recordingData, setRecordingData] = useState<boolean>(false);
  const [pauseSamplePlayBtn, setPauseSamplePlayBtn] = useState<boolean>(false);
  const [sttVendor, setSttVendor] = useState<string>(sttModelData.vendor);
  const [sttModel, setSttModel] = useState<string>(sttModelData.model);
  const [sttLanguage, setSttLanguage] = useState<string>(sttModelData.language);
  const [llmVendor, setLlmVendor] = useState<string>(llmModelData.vendor);
  const [llmModel, setLLMModel] = useState<string>(llmModelData.model);
  const [llmRegion, setLlmRegion] = useState<string>(llmModelData.region ?? null);
  const [values, setValues] = useState<any>();
  const [sttEndpointing, setSttEndpointing] = useState<string>(sttModelData.endpointing);

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(getLoaderValue);

  async function configurations() {
    const res = await dispatch(getModelConfigurationsAction());
    setValues(res);
  }

  useEffect(() => {
    configurations();
  }, []);

  const handleOptionChange = (event: any) => {
    setSelectedTTSModelOption(event.target.value);
  };

  function radioHandler() {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
    }
  }

  async function handleEditCampaignSubmit() {
    dispatch({ type: LOADING_ON });
    let endpointing = parseInt(sttEndpointing, 10);

    const data: any = {
      // msmeId: msmeCampaignId,
      name: msmeName,
      greetingMessage: greetingMessage,
      active: active,
      maxDailyLimit: dailyLimit,
      ttsModel: {
        model: selectedTTSModelOption,
        vendor: ttsVendor,
        ssmlConfigs: {
          speed: ssmlConfigsSpeed
        }
      },
      sttModel: {
        model: sttModel,
        vendor: sttVendor,
        language: sttLanguage,
        endpointing: endpointing
      },
      llmModel: {
        model: llmModel,
        vendor: llmVendor
      }
    };
    try {
      if (llmVendor == 'microsoft') data.llmModel.region = llmRegion;
      const res = await dispatch(updateSpecificCampaignDetailAction(data));
      dispatch({ type: LOADING_OFF });
      if (res.status == 200) {
        navigate(-1);
        toast.success('Campaign updated successfully');
      } else {
        showAlert(dispatch, res?.message);
      }
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      console.log(e);
    }
  }

  async function handlePLaySampleAudio() {
    setPauseSamplePlayBtn(true);
    const data: any = {
      ttsModel: {
        vendor: ttsVendor,
        model: selectedTTSModelOption,
        ssmlConfigs: {
          speed: ssmlConfigsSpeed
        }
      },
      speechText: speechText
    };

    if (selectedTTSModelOption == 'Kritika Custom Voice (en-IN)Neural')
      data.ttsModel.deploymentId = '7c638e13-8ebe-4b2f-920a-bcf2527d8d77';
    try {
      const res = await dispatch(sampleAudioDataAction(data));
      setRecordingData(true);
      const file = base64toBlob(res?.data?.data?.data, 'audio/mp3');
      const audioFile = URL.createObjectURL(file);
      const audio = new Audio();
      audio.src = audioFile;
      audio.play();
    } catch (e) {
      console.log(e);
    }
    setPauseSamplePlayBtn(false);
  }

  function getIndex(data: string, arr: any) {
    let i = -1;
    if (!arr) return -1;
    for (i = 0; i < arr.length; i++) {
      if (arr[i] == data) {
        return i + 1;
      }
    }
    return -1;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 m-auto">
          <div className="edit-card mt-5 p-4">
            <form
              onSubmit={(e: any) => {
                e?.preventDefault();
                handleEditCampaignSubmit();
              }}
            >
              <div>
                <Para className="mb-2">Intelligence Layer :</Para>

                <div className="border rounded p-4 mb-4">
                  <SelectInput
                    labelText="Vendor"
                    optionsList={
                      values
                        ? [
                            'Select from options',
                            ...values?.data?.data?.llmModel?.map((item: any) => item.vendor)
                          ]
                        : ['Select from options']
                    }
                    setData={(e) => {
                      setLlmVendor(e);
                      setLLMModel('');
                      setLlmRegion('');
                    }}
                    defaultValue={getIndex(
                      llmModelData.vendor,
                      values?.data?.data?.llmModel?.map((item: any) => item.vendor)
                    )}
                    required
                  ></SelectInput>

                  {llmVendor == 'microsoft' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLLMModel(e);
                      }}
                      defaultValue={getIndex(
                        llmModelData.model,
                        values?.data?.data?.llmModel?.find((item: any) => item.vendor === llmVendor)
                          ?.model
                      )}
                      required
                    ></SelectInput>
                  )}

                  {llmVendor == 'openAI' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLLMModel(e);
                      }}
                      defaultValue={getIndex(
                        llmModelData.model,
                        values?.data?.data?.llmModel?.find((item: any) => item.vendor === llmVendor)
                          ?.model
                      )}
                      required
                    ></SelectInput>
                  )}

                  {llmVendor == 'google' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLLMModel(e);
                      }}
                      defaultValue={getIndex(
                        llmModelData.model,
                        values?.data?.data?.llmModel?.find((item: any) => item.vendor === llmVendor)
                          ?.model
                      )}
                      required
                    ></SelectInput>
                  )}

                  {llmVendor == 'microsoft' && (
                    <SelectInput
                      labelText="Region"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.llmModel?.find(
                                (item: any) => item.vendor === llmVendor
                              )?.region
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setLlmRegion(e);
                      }}
                      defaultValue={
                        llmModelData.region &&
                        getIndex(
                          llmModelData.region,
                          values?.data?.data?.llmModel?.find(
                            (item: any) => item.vendor === llmVendor
                          )?.region
                        )
                      }
                      required
                    ></SelectInput>
                  )}
                </div>
                <Para className="mb-2">Speaker Persona :</Para>

                <div className="mb-4 p-4 border rounded">
                  <SelectInput
                    labelText="Vendor"
                    optionsList={
                      values
                        ? [
                            'Select from options',
                            ...values?.data?.data?.ttsModel?.map((item: any) => item.vendor)
                          ]
                        : ['Select from options']
                    }
                    setData={(e) => {
                      setTtsVendor(e);
                      setSelectedTTSModelOption('');
                    }}
                    defaultValue={getIndex(
                      ttsModel.vendor,
                      values?.data?.data?.ttsModel?.map((item: any) => item.vendor)
                    )}
                    required
                  ></SelectInput>

                  {ttsVendor == 'Voizo proprietary' && (
                    <div className="row mb-4">
                      <div className="col-sm-3">
                        <label className="w-100">
                          <input
                            type="radio"
                            name="test"
                            value="hi-IN-SwaraNeural"
                            checked={
                              selectedTTSModelOption ===
                              values?.data?.data?.ttsModel.map((item: any) => item.model)?.[0][0]
                            }
                            onChange={handleOptionChange}
                          />
                          <div className="content-container">
                            <div className="img-box">
                              <img src={VoiceWoman} alt="Option 1" className="man-img" />
                            </div>
                            <Heading3>Swara</Heading3>
                          </div>
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <label className="w-100">
                          <input
                            type="radio"
                            name="test"
                            value="Kritika Custom Voice (en-IN)Neural"
                            checked={
                              selectedTTSModelOption ===
                              values?.data?.data?.ttsModel.map((item: any) => item.model)?.[0][1]
                            }
                            onChange={handleOptionChange}
                          />
                          <div className="content-container mt-sm-0 mt-3">
                            <div className="img-box">
                              <img src={Woman2} alt="Option 2" className="man-img" />
                            </div>
                            <Heading3>Kritika</Heading3>
                          </div>
                        </label>
                      </div>
                    </div>
                  )}

                  {ttsVendor == 'google' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.ttsModel?.find(
                                (item: any) => item.vendor[0] === ttsVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSelectedTTSModelOption(e);
                      }}
                      defaultValue={getIndex(
                        ttsModel.model,
                        values?.data?.data?.ttsModel?.find(
                          (item: any) => item.vendor[0] === ttsVendor
                        )?.model
                      )}
                      required
                    ></SelectInput>
                  )}

                  <label htmlFor="customRange3" className="form-label">
                    SSML Configuration Speed : {ssmlConfigsSpeed}
                  </label>
                  <input
                    type="range"
                    onChange={(e) => {
                      setSsmlConfigsSpeed(e.target.value);
                    }}
                    value={ssmlConfigsSpeed}
                    className="form-range"
                    min="0.5"
                    max="2"
                    step="0.1"
                    id="ssmlConfigSpeedRange"
                  ></input>

                  <Para className="mb-2">Sample Speech Text :</Para>
                  <textarea
                    className="text-area mb-4 w-100"
                    rows={10}
                    value={speechText}
                    maxLength={100}
                    onChange={(e: any) => {
                      setSpeeechText(e.target.value);
                    }}
                  ></textarea>

                  {ttsVendor && selectedTTSModelOption && speechText.length > 4 && (
                    <PrimaryButton
                      handleBtnClick={handlePLaySampleAudio}
                      isDisabled={pauseSamplePlayBtn}
                    >
                      <>
                        <PlayCircle size={32} weight="light" className="me-2" />
                        Play Sample Audio
                      </>
                    </PrimaryButton>
                  )}
                </div>

                <Para className="mb-2">Speech Recognition :</Para>

                <div className="border rounded p-4 mb-4">
                  <SelectInput
                    labelText="Vendor"
                    optionsList={
                      values
                        ? [
                            'Select from options',
                            ...values?.data?.data?.sttModel?.map((item: any) => item.vendor)
                          ]
                        : ['Select from options']
                    }
                    setData={(e) => {
                      if (e == 'Select from options') {
                        setSttVendor('');
                      } else {
                        setSttModel('');
                        setSttVendor(e);
                      }
                    }}
                    defaultValue={getIndex(
                      sttModelData.vendor,
                      values?.data?.data?.sttModel?.map((item: any) => item.vendor)
                    )}
                    required
                  />

                  {sttVendor == 'google' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.sttModel?.find(
                                (item: any) => item.vendor === sttVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSttModel(e);
                      }}
                      defaultValue={getIndex(
                        sttModelData.model,
                        values?.data?.data?.sttModel?.find((item: any) => item.vendor === sttVendor)
                          ?.model
                      )}
                      required
                    />
                  )}

                  {sttVendor == 'deepgram' && (
                    <SelectInput
                      labelText="Model"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.sttModel?.find(
                                (item: any) => item.vendor === sttVendor
                              )?.model
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSttModel(e);
                      }}
                      defaultValue={getIndex(
                        sttModelData.model,
                        values?.data?.data?.sttModel?.find((item: any) => item.vendor === sttVendor)
                          ?.model
                      )}
                      required
                    />
                  )}
                  {sttVendor && (
                    <SelectInput
                      labelText="Language"
                      optionsList={
                        values
                          ? [
                              'Select from options',
                              ...values?.data?.data?.sttModel?.find(
                                (item: any) => item.vendor == sttVendor
                              )?.language
                            ]
                          : ['Select from options']
                      }
                      setData={(e) => {
                        setSttLanguage(e);
                      }}
                      defaultValue={getIndex(
                        sttModelData.language,
                        values?.data?.data?.sttModel?.find((item: any) => item.vendor == sttVendor)
                          ?.language
                      )}
                      required
                    />
                  )}
                  <label htmlFor="customRange3" className="form-label">
                    STT endpointing : {sttEndpointing}
                  </label>
                  <input
                    type="range"
                    onChange={(e) => {
                      setSttEndpointing(e.target.value);
                    }}
                    value={sttEndpointing}
                    className="form-range"
                    min="100"
                    max="1500"
                    step="1"
                    id="ssmlConfigSpeedRange"
                    required
                  ></input>
                </div>

                <PrimaryTextfield
                  onChange={(value) => setGreetingMessage(value)}
                  label="Greeting Message"
                  placeholder="Hello, welcome to Haldiram's !"
                  value={greetingMessage}
                  fieldName="name"
                  type="text"
                  required
                />

                <div className="mb-4">
                  <Para>Status : </Para>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inActive"
                      id="true"
                      value="true"
                      onChange={radioHandler}
                      checked={active}
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="false"
                      name="inActive"
                      value="false"
                      onChange={radioHandler}
                      checked={!active}
                    />
                    <label className="form-check-label">Inactive</label>
                  </div>
                </div>

                <label htmlFor="dailyLimitNumberInput">Enter a Maximum daily limit </label>
                <br />
                <PrimaryTextfield
                  class="mt-2 mb-4 w-100 number-input"
                  type="number"
                  fieldName="Number"
                  placeholder="100"
                  value={dailyLimit}
                  onChange={(e: any) => {
                    setDailyLimit(e);
                  }}
                ></PrimaryTextfield>

                <PrimaryButton
                  className="w-100"
                  handleBtnClick={() => {}}
                  type={ButtonType.SUBMIT}
                  isLoading={isLoading}
                  loadingText="Please wait"
                >
                  Update
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
