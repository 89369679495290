import { combineReducers } from 'redux';
import uiReducer from './states/ui/ui.reducer';
import msmeReducer from './states/msme/msme.reducer';

const rootReducer = combineReducers<any>({
  msme: msmeReducer,
  ui: uiReducer
});

export default rootReducer;
