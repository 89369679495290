import { get, post, put } from '../../services/api/api';
import {
  bulkCallSchedule,
  bulkUpload,
  createMsmeCampaign,
  exportCallDetailsList,
  getCallRecording,
  getCampaignCalls,
  getCampaignConversion,
  getCampaignSummary,
  getInstructions,
  getModelConfigurations,
  getMsmeCampaigns,
  getMsmeUser,
  getSpecificCampaignDetail,
  postSingleNumber,
  retryCalls,
  sampleAudio,
  sampleBulkUploadData,
  updateInstructions,
  updateSpecificCampaignDetail
} from '../../services/api/apiConstants';

export const getMsmeUserService = () => get(getMsmeUser);

export const getMsmeCampaignsService = () => get(getMsmeCampaigns);

export const getCampaignCallsService = (urlParams: string) => get(getCampaignCalls + urlParams);

export const getCampaignSummaryService = (urlParams: string) => get(getCampaignSummary + urlParams);

export const getCampaignConversionService = (urlParams: string) =>
  get(getCampaignConversion + urlParams);

export const getSpecificCampaignDetailService = (urlParams: string) =>
  get(getSpecificCampaignDetail + urlParams);

export const bulkUploadService = (data: any) => post(bulkUpload, data, 'file', 'blob');

export const updateSpecificCampaignDetailService = (data: any) =>
  put(updateSpecificCampaignDetail, data);

export const getCallRecordingService = (urlParams: string) => get(getCallRecording + urlParams);

export const addSingleCallNumberService = (data: any) => post(postSingleNumber, data);

export const getInstructionsService = (urlParams: string) => get(getInstructions + urlParams);

export const updateInstructionsService = (data: any) => put(updateInstructions, data);

export const retryCallsService = (data: any) => post(retryCalls, data);

export const bulkCallScheduleService = (data: any) => post(bulkCallSchedule, data);

export const exportCallDetailsListService = (urlParams: string) =>
  get(exportCallDetailsList + urlParams, '', 'blob');

export const sampleBulkUploadDataService = (urlParams: string) =>
  get(sampleBulkUploadData + urlParams, '', 'blob');

export const createMsmeCampaignServicee = (data: any) => post(createMsmeCampaign, data);

export const postSampleAudioService = (data: any) => post(sampleAudio, data);

export const getModelConfigurationsService = () => get(getModelConfigurations);
