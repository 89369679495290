import React, { useEffect, useRef, useState } from 'react';
import { Heading3, Para } from '../../atoms/typography/typography';
import { PlayCircle } from 'phosphor-react';
import { base64toBlob, convertISOToDate, convertTime, displayDuration } from '../../../utils/utils';
import { displayCallStatus, displayCallType, displayDisconnectedReason } from '../../../utils/ui';
import { getCallRecordingAction } from '../../../redux/states/msme/msme.actions';
import { useDispatch } from 'react-redux';
import { CallStatusType } from '../../../models/ui.enum';

export default function AttemptCard({
  item,
  attempt,
  playRecording,
  playAudios,
  currentAudioUrl
}: any) {
  const dispatch: any = useDispatch();
  const [audioUrl, setAudioUrl] = useState<any>();
  const [recordingData, setRecordingData] = useState<boolean>(false);
  const [transcription, setTranscription] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const handlePlayAudio = async (recordingKey: string) => {
    const response = await dispatch(getCallRecordingAction(recordingKey || ''));
    setRecordingData(true);
    const file = base64toBlob(response?.data?.data, 'audio/mp3');
    const url = URL.createObjectURL(file);
    setAudioUrl(url);
    playAudios(url, audioRef.current);
  };

  const handleTranscriptionTextToggle = () => {
    setTranscription(!transcription);
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef?.current?.pause();
    }
  };

  useEffect(() => {
    if (!playRecording) {
      pauseAudio();
      setAudioUrl('');
      setRecordingData(false);
      setTranscription(false);
    }
  }, [playRecording]);

  return (
    <div className="call-details-table-container mt-2 p-2">
      <div className="d-flex justify-content-between ">
        <Para className="">
          <span style={{ color: 'var(--secondary' }} className="fw-600">
            Attempt: {attempt}
          </span>
        </Para>
        <Para className="">
          <span className="fw-600  ">{displayCallType(item?.type)}</span>
        </Para>
      </div>
      <Para>
        <>
          <span className="fw-600">Call Duration: </span>
          {displayDuration(item.duration)}
        </>
      </Para>
      <Para>
        <>
          <span className="fw-600">Call Created: </span>
          {convertISOToDate(item.createdAt)} | {convertTime(item.createdAt)}
        </>
      </Para>
      <Para>
        <div className="d-flex">
          <span className="fw-600 me-2">Call Status: </span>
          {displayCallStatus(item.status)}
        </div>
      </Para>
      <Para>
        <>
          <span className="fw-600">Call Summary: </span>
          {item.summary ?? 'N/A'}
        </>
      </Para>
      {item.status === CallStatusType.COMPLETED ? (
        <Para>
          <div className="d-flex">
            <>
              <span className="fw-600 me-2">Disconnect Reason: </span>
              {displayDisconnectedReason(item.disconnectedReason)}
            </>
          </div>
        </Para>
      ) : (
        <></>
      )}
      <Para>
        <>
          <span className="fw-600">Call Recording: </span>
          {item.recordingKey ? (
            <PlayCircle
              role="button"
              style={{ color: 'var(--primary)' }}
              onClick={() => {
                handlePlayAudio(item?.recordingKey);
              }}
              size={32}
              weight="fill"
            />
          ) : (
            'N/A'
          )}
        </>
      </Para>
      {recordingData && audioUrl && currentAudioUrl === audioUrl && (
        <audio ref={audioRef} controls src={audioUrl}></audio>
      )}
      {item.status === CallStatusType.COMPLETED ? (
        <div className="">
          <Para>
            <div className="">
              <div className="d-flex">
                <span className="fw-600 me-2">Transcription: </span>
                <div onClick={handleTranscriptionTextToggle} className="transcription-text">
                  <Heading3>{transcription ? 'Hide conversation' : 'Show conversation'}</Heading3>
                </div>
              </div>
              {transcription && (
                <div className="primary-input m-2">
                  {item?.userConversation.map((data: any) => (
                    <div className="d-flex">
                      <Para>{data.type + ':'}</Para>
                      <Para textColor="var(--textGray)" className="ps-2">
                        {data.message}
                      </Para>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Para>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
