// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  sendEmailVerification,
  getIdTokenResult,
  sendPasswordResetEmail,
  applyActionCode,
  confirmPasswordReset
} from 'firebase/auth';
import { toast } from 'react-toastify';
import { saveData } from './redux/services/handleData/localStorage';
import { WEBSITE_URL } from './utils/config';

let firebaseConfig = {
  apiKey: 'AIzaSyBdvusAGkSWCa7LP7itqHLSqPGT9Imt2KM',
  authDomain: 'msme-stage.firebaseapp.com',
  projectId: 'msme-stage',
  storageBucket: 'msme-stage.appspot.com',
  messagingSenderId: '276517838854',
  appId: '1:276517838854:web:9ffab5c2bae545151e6387',
  measurementId: 'G-2FKKJQQW7C'
};

if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyAX3FL8_7GF8GqLsf3ZU84vy5dk3Plym_4',
    authDomain: 'msme-voizo.firebaseapp.com',
    projectId: 'msme-voizo',
    storageBucket: 'msme-voizo.appspot.com',
    messagingSenderId: '281015063413',
    appId: '1:281015063413:web:0fe7a88d2417c0c20f393c',
    measurementId: 'G-9J8FJ6XJLQ'
  };
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

// https://firebase.google.com/docs/auth/web/password-auth

async function signUpUser(email: string, password: string) {
  try {
    const userCredential: UserCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user: User = userCredential.user;
    if (user && user.emailVerified === false) {
      await sendEmailVerification(user, {
        url: WEBSITE_URL,
        handleCodeInApp: true
      });
    }
    return user;
  } catch (error: any) {
    return error;
  }
}

async function signInUser(email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user: User = userCredential.user;

    if (user && user.emailVerified === false) {
      await sendEmailVerification(user, {
        url: WEBSITE_URL,
        handleCodeInApp: true
      });
    }
    return user;
  } catch (error: any) {
    return error;
  }
}

async function refreshUserToken(user: any) {
  try {
    const refreshedToken = await user.getIdToken(true); // Force token refresh
    console.log(refreshedToken);
    saveData('token', refreshedToken);
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
}

function getTokenExpirationTime(userTokenInfo: any): number {
  const expirationDate = new Date(userTokenInfo.expirationTime);
  return expirationDate.getTime() / 1000;
}

async function getTokenData() {
  auth.onAuthStateChanged(async (user) => {
    try {
      if (!user) return;
      const userTokenInfo = await getIdTokenResult(user);
      const currentTime = new Date().getTime() / 1000;
      const tokenExpirationTime = getTokenExpirationTime(userTokenInfo);
      const timeUntilExpiration = tokenExpirationTime - currentTime;
      const timeToRefresh = timeUntilExpiration - 300; // 5 minutes
      const refreshInterval = Math.max(0, timeToRefresh * 1000);
      if (timeToRefresh < 0) {
        await refreshUserToken(user);
      }

      setTimeout(getTokenData, refreshInterval);
    } catch (error) {
      console.error('Error in onAuthStateChanged:', error);
    }
  });
}

async function forgotPassword(email: string) {
  try {
    const data = await sendPasswordResetEmail(auth, email, {
      url: WEBSITE_URL,
      handleCodeInApp: true
    });
    return data;
  } catch (error: any) {
    return error;
  }
}

async function verifyEmail(code: string) {
  try {
    await applyActionCode(auth, code);
    toast.success('Email successfully verified!');
  } catch (error) {
    toast.error('Error verifying email');
  }
}

async function resetPassword(oobCode: string, newPassword: string) {
  try {
    const data = await confirmPasswordReset(auth, oobCode, newPassword);
    return data;
  } catch (error) {
    return error;
  }
}

export {
  auth,
  signUpUser,
  signInUser,
  signOut,
  analytics,
  getTokenData,
  forgotPassword,
  verifyEmail,
  resetPassword
};
