import React, { useEffect, useRef, useState } from 'react';
import { StandardBtn } from '../components/molecules/buttons/buttons';
import { base64ToArrayBuffer, blobToBase64 } from '../utils/utils';
import { PhoneCall, PhoneSlash } from 'phosphor-react';
import Robot from '../assets/robot.png';
import Logo from '../assets/logo.svg';
import { useParams } from 'react-router-dom';

export default function Voip() {
  const audioRef = useRef<any>(null);
  const mediaSourceRef = useRef<any>(null);
  const sourceBufferRef = useRef<any>(null);
  const queue = useRef<any>([]);
  const audioContext = new window.AudioContext({ latencyHint: 'playback', sampleRate: 44100 });
  const [isRecording, setIsRecording] = useState(false);
  const [streamSid, setStreamSid] = useState('');
  const nextNoteTimeRef = useRef<number>(0);
  const { campaignId } = useParams();
  const activeSources = useRef<AudioBufferSourceNode[]>([]);

  const socket = useRef<WebSocket | null>(null);

  useEffect(() => {
    const streamSidTemp = `e2d13228d7f6be1fe3${Date.now()}`;
    setStreamSid(streamSidTemp);

    // Function to handle source buffer updates
    function updateSourceBuffer() {
      if (
        mediaSourceRef.current &&
        mediaSourceRef.current.readyState === 'open' &&
        sourceBufferRef.current &&
        !sourceBufferRef.current.updating &&
        queue.current.length > 0
      ) {
        sourceBufferRef.current.appendBuffer(queue.current.shift());
      }
    }

    // Function to handle the 'sourceopen' event
    function sourceOpen() {
      const mediaSource = mediaSourceRef.current;
      sourceBufferRef.current = mediaSource?.addSourceBuffer('audio/webm; codecs="opus"'); // Adjust the MIME type accordingly
      sourceBufferRef.current.addEventListener('updateend', updateSourceBuffer, false);
    }

    // Initialize MediaSource and assign it to the audio element
    if (!mediaSourceRef.current) {
      mediaSourceRef.current = new MediaSource();
      const url = URL.createObjectURL(mediaSourceRef.current);
      if (audioRef.current) {
        audioRef.current.src = url;
      }
      URL.revokeObjectURL(url);
      mediaSourceRef.current.addEventListener('sourceopen', sourceOpen, false);
    }
  }, []);

  function decodeAndPlayAudioData(audioData: any) {
    audioContext.decodeAudioData(
      audioData,
      (buffer) => {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        const currentTime = audioContext.currentTime;
        const nextAvailableTime = Math.max(currentTime, nextNoteTimeRef.current);
        source.start(nextAvailableTime);
        activeSources.current.push(source);
        nextNoteTimeRef.current = nextAvailableTime + buffer.duration;
        source.onended = () => {
          source.disconnect();
          activeSources.current = activeSources.current.filter((s) => s !== source);
          // Disconnect after playback to free up resources.
        };
      },
      (error) => console.error('Error with decoding audio data', error)
    );
  }

  function stopAllAudio() {
    activeSources.current.forEach((source) => {
      source.stop(); // This stops playback immediately
      source.disconnect(); // Optional, disconnects from the audio context
    });
    activeSources.current = []; // Clear the array of sources
  }

  function establishSocketConnection() {
    // Create WebSocket connection.
    socket.current = new WebSocket('wss://beta-prod-api.voizo.ai');

    // Listen for messages
    socket.current.addEventListener('message', function (event: any) {
      console.log('Message from server ', event.data);
      if (event.data == '') {
        console.log('empty');
        stopAllAudio();
      } else {
        const base64Data = event.data;
        const audioData = base64ToArrayBuffer(base64Data);
        if (audioData) decodeAndPlayAudioData(audioData);
      }
    });

    // Listen for possible errors
    socket.current.addEventListener('error', function (event: any) {
      console.error('WebSocket error observed:', event);
    });

    // Cleanup function to close the socket when the component unmounts
    return () => socket.current?.close();
  }

  let randomNumber = `voip-${Math.random().toString().replace('0.', '')}`;

  function socketConnectionRequest() {
    establishSocketConnection();
    const payload = {
      event: 'start',
      stream_sid: streamSid,
      client: 'voizo',
      start: {
        stream_sid: streamSid,
        call_sid: streamSid,
        from: randomNumber,
        to: campaignId,
        media_format: {
          encoding: 'base64',
          sample_rate: '8000',
          bit_rate: '128kbps'
        }
      }
    };
    const initialPayload = JSON.stringify(payload);
    // Connection opened
    socket.current?.addEventListener('open', function () {
      console.log('WebSocket is connected.');
      socket.current?.send(initialPayload);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      startRecordingAndSending();
    });

    socket.current?.addEventListener('close', (event) => {
      console.error(`WebSocket closed unexpectedly. Code: ${event.code}, Reason: ${event.reason}`);
    });

    socket.current?.addEventListener('error', (event) => {
      console.error('WebSocket error observed:', event);
    });
  }

  const playAudio = () => {
    setIsRecording(true);
  };

  const stopAudio = () => {
    setIsRecording(false);
    window.location.reload();
  };

  const startRecordingAndSending = async () => {
    playAudio();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm;codecs=opus'
      });

      mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          const base64Chunk = await blobToBase64(event.data);
          const audioChunkData = {
            event: 'media',
            stream_sid: streamSid,
            client: 'voizo',
            media: { payload: base64Chunk }
          };
          console.log(audioChunkData);

          if (socket.current && socket.current.readyState === WebSocket.OPEN) {
            socket.current.send(JSON.stringify(audioChunkData));
          } else {
            console.log('WebSocket is not open. Current state:', socket.current?.readyState);
          }
        }
      };
      mediaRecorder.start(20);
    } catch (error) {
      console.error('Error starting audio recording:', error);
    }
  };

  return (
    <div className="position-relative">
      <div className="row justify-content-center text-center mt-5 p-4 mx-0">
        <div className="col-lg-4 login-card p-4">
          <div className="main-content-wrapper"></div>
          <div className="d-flex justify-content-end">
            <img src={Logo} width={60} />
          </div>
          <div className="py-5">
            <img src={Robot} width={100} />
          </div>
          {!isRecording ? (
            <StandardBtn
              btnBackgroundColor="var(--green)"
              btnTextColor="var(--white)"
              className="w-100 answer"
              handleBtnClick={() => {
                socketConnectionRequest();
              }}
            >
              <div>
                <PhoneCall size={20} weight="fill" className="me-2" color="var(--white)" />
                {'Start Call'}
              </div>
            </StandardBtn>
          ) : (
            <StandardBtn
              btnBackgroundColor="var(--red)"
              btnTextColor="var(--white)"
              className="w-100 answer"
              handleBtnClick={() => {
                socket.current?.close();
                stopAudio();
              }}
            >
              <div>
                <PhoneSlash size={20} weight="fill" className="me-2" color="var(--white)" />
                {'End Call'}
              </div>
            </StandardBtn>
          )}
        </div>
      </div>
    </div>
  );
}
