import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { About } from './pages/dashboard/About';
import { Home } from './pages/dashboard/Home';
import Login from './pages/auth/login';
import Navbar from './components/navbar/Navbar';
import { CampaignDetails } from './pages/dashboard/campaigns/campaign-details/campaignDetails';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadData } from './redux/services/handleData/localStorage';
import { getTokenData } from './firebase';
import ForgotPassword from './pages/auth/forgotPassword';
import ResetPassword from './pages/auth/resetPassword';
import { InfoModal } from './components/molecules/modal/modal';
import noInternet from './assets/no-internet.svg';
import { Heading2, Para } from './components/atoms/typography/typography';
import { useSelector } from 'react-redux';
import {
  getAlertHeadingValue,
  getAlertParaValue,
  getAlertValue
} from './redux/states/ui/ui.services';
import { useDispatch } from 'react-redux';
import { ALERT_MODAL_OFF } from './redux/states/ui/ui.actions';
import EditCampaignDetails from './pages/dashboard/campaigns/edit-campaign-details/editCampaignDetails';
import Signup from './pages/auth/signup';
import EditDetails from './pages/dashboard/campaigns/edit-details/editDetails';
import CreateCampaign from './pages/dashboard/campaigns/create-campaign/createCampaign';
import Voip from './pages/voip';
if (process.env.REACT_APP_ENV != 'stage') {
  Sentry.init({
    dsn: 'https://043d6e19e521aacd333fd089b07e2c44@o4505577648029696.ingest.sentry.io/4505905971462144',
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const App: React.FC = () => {
  const alertState = useSelector(getAlertValue);
  const alertHeading = useSelector(getAlertHeadingValue);
  const alertPara = useSelector(getAlertParaValue);
  const dispatch = useDispatch();

  function handleAlertBtnClick() {
    dispatch({ type: ALERT_MODAL_OFF });
  }

  useEffect(() => {
    getTokenData();
  }, []);

  function routeList() {
    if (loadData('token')) {
      return (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/campaign-detail/:campaignId" element={<CampaignDetails />} />
          <Route path="/edit-campaign-details/:campaignId" element={<EditCampaignDetails />} />
          <Route path="/edit/:campaignId" element={<EditDetails />} />
          <Route path="/create-campaign/:msmeId" element={<CreateCampaign />} />
          <Route path="*" element={<Navigate to="/" replace />}></Route>
          <Route path="/voip/:campaignId" element={<Voip />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<Navigate to="/" replace />}></Route>
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/voip/:campaignId" element={<Voip />} />
        </Routes>
      );
    }
  }
  return (
    <>
      <Navbar />
      {routeList()}
      <ToastContainer />
      <InfoModal active={alertState} onClose={handleAlertBtnClick}>
        <div className="text-center">
          <img src={noInternet} className="mb-3" />
          <Heading2 className="mb-2">{alertHeading}</Heading2>
          <Para textColor="var(--textGray)" className="mb-4">
            {alertPara}
          </Para>
        </div>
      </InfoModal>
    </>
  );
};

export default App;
