import * as React from 'react';

import './modal.css';
import { X } from 'phosphor-react';
import { PrimaryButton } from '../buttons/buttons';

interface ModalProps {
  active?: boolean;
  onClose?: () => void;
}

type BaseModalProps = ModalProps & {
  children?: React.ReactNode;
};

interface AlertModalProps extends BaseModalProps {
  primaryBtnTitle: string;
  onPrimaryBtnClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

function BaseModal(props: BaseModalProps) {
  return (
    <div
      role="dialog"
      style={{
        display: props.active ? 'block' : 'none'
      }}
      className="modal-wrapper"
    >
      <div className={`modal-dialog`}>
        <div
          className="modal-content overflow-scroll p-md-5 px-4 pt-5 pb-4"
          style={{ maxHeight: '500px' }}
        >
          <X onClick={props.onClose} className={`modal-icon`} role="button" size={24} />
          {props.children}
        </div>
      </div>
    </div>
  );
}

export function InfoModal(props: BaseModalProps) {
  return <BaseModal {...props}>{props.children}</BaseModal>;
}

export function AlertModal(props: AlertModalProps) {
  return (
    <BaseModal {...props}>
      {props.children}
      {props.primaryBtnTitle != '' && (
        <PrimaryButton
          className="btn-block mt-md-4 mt-3"
          handleBtnClick={props.onPrimaryBtnClick}
          isLoading={props.isLoading}
          loadingText="Please wait..."
          isDisabled={props.isDisabled}
        >
          {props.primaryBtnTitle}
        </PrimaryButton>
      )}
    </BaseModal>
  );
}
