import React from 'react';
import { CallStatusType, CampaignCallType, DisconnectedReason } from '../models/ui.enum';

export function breakLines(inputString: string) {
  const lines = inputString.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < inputString?.split('\n').length - 1 ? (
        <div style={{ display: 'block', height: '8px' }}></div>
      ) : null}
    </React.Fragment>
  ));
  return <div>{lines}</div>;
}

export function displayCallStatus(status: string) {
  if (status == CallStatusType.COMPLETED)
    return (
      <div className="pill" style={{ background: 'rgb(204 239 220)', color: 'rgb(40, 199, 111)' }}>
        Completed
      </div>
    );
  else if (status == CallStatusType.NOT_REACHABLE)
    return (
      <div className="pill" style={{ background: 'lightgray', color: 'gray' }}>
        Not reachable
      </div>
    );
  else if (status == CallStatusType.SCHEDULED)
    return (
      <div className="pill" style={{ background: 'rgb(214 213 241)', color: '#6a60de' }}>
        Scheduled
      </div>
    );
  else if (status == CallStatusType.RESCHEDULED)
    return (
      <div className="pill" style={{ background: '#c1d8f0', color: '#4663ac' }}>
        Re-scheduled
      </div>
    );
  else if (status == CallStatusType.PENDING)
    return (
      <div className="pill" style={{ background: '#fad8d8', color: '#dc0000' }}>
        Pending
      </div>
    );
  else if (status == CallStatusType.ATTEMPTED)
    return (
      <div className="pill" style={{ background: '#a2fda2', color: '#235c23' }}>
        Attempted
      </div>
    );
  else if (status == CallStatusType.BUSY)
    return (
      <div className="pill" style={{ background: '#c5dad2', color: '#235c23' }}>
        Busy
      </div>
    );
  else if (status == CallStatusType.NO_ANSWER)
    return (
      <div className="pill" style={{ background: '#d7d07b', color: '#a48825' }}>
        No Answer
      </div>
    );
  else if (status == CallStatusType.FAILED)
    return (
      <div className="pill" style={{ background: '#ffaac2', color: '#c53939' }}>
        Failed
      </div>
    );
  else if (status == CallStatusType.INITIATED)
    return (
      <div className="pill" style={{ background: '#7de2e2', color: '#2d617a' }}>
        Initiated
      </div>
    );
  else if (status == CallStatusType.UNSUCCESSFULL)
    return (
      <div className="pill" style={{ background: '#bcbbbb', color: 'black' }}>
        Unsuccessful
      </div>
    );
  else return <div>{status}</div>;
}

export function displayCallType(type: string) {
  if (type === CampaignCallType.BOTH) {
    return (
      <>
        <p className="pill outbound-pill">OUTBOUND</p>
        <p className="pill inbound-pill">INBOUND</p>
      </>
    );
  } else if (type === CampaignCallType.INCOMING) {
    return (
      <>
        <p className="pill inbound-pill">INBOUND</p>
      </>
    );
  } else if (type === CampaignCallType.OUTGOING) {
    return (
      <>
        <p className="pill outbound-pill">OUTBOUND</p>
      </>
    );
  }
}

export function displayDisconnectedReason(type: string) {
  if (type === DisconnectedReason.USER_DISCONNECTED) {
    return (
      <>
        <p className="pill outbound-pill mb-0">User Disconnected</p>
      </>
    );
  } else if (type === DisconnectedReason.SYSTEM_DISCONNECTED) {
    return (
      <>
        <p className="pill inbound-pill  mb-0">System Disconnected</p>
      </>
    );
  } else if (type === DisconnectedReason.USER_DISCONNECTED_BEFORE_VOICEBOT) {
    return (
      <>
        <p
          className="pill mb-0"
          style={{ background: 'rgb(214, 213, 241)', color: 'rgb(106, 96, 222)' }}
        >
          User Disconnected Before Voicebot
        </p>
      </>
    );
  }
}
