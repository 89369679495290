import { useNavigate, useParams } from 'react-router-dom';
import {
  FontWeight,
  Heading1,
  Heading2,
  Para
} from '../../../../components/atoms/typography/typography';
import { PrimaryButton, SecondaryButton } from '../../../../components/molecules/buttons/buttons';
import { useState } from 'react';
import UploadFile from '../../../../components/organism/upload-file/uploadFile';
import { AlertModal, InfoModal } from '../../../../components/molecules/modal/modal';
import NoInternet from '../../../../assets/no-internet.svg';
import {
  addSingleCallNumberAction,
  bulkCallScheduleAction,
  bulkUploadAction,
  sampleBulkUploadDataAction
} from '../../../../redux/states/msme/msme.actions';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { useDispatch } from 'react-redux';
import { convertBinaryToXlsx, showAlert } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import SelectInput from '../../../../components/organism/select-input/selectInput';
import { useSelector } from 'react-redux';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { ButtonType } from '../../../../models/ui.enum';

interface SpecificCampaignProp {
  name?: string;
  greetingMessage?: string;
  active?: boolean;
  maxDailyLimit: number;
  type?: string;
  mobileNumber?: string;
  ttsModel: Object;
  llmModel: Object;
  sttModel: Object;
  userMetaData: Array<Object>;
}

interface MetaDataProps {
  label?: string;
  value?: string;
}

interface CampaignDetailsTableProps {
  specificCampaign: SpecificCampaignProp;
  campaignId?: string;
}

export default function SpecificCampaignTable({
  specificCampaign
}: // campaignId
CampaignDetailsTableProps) {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [uploadFile, setUploadFile] = useState(false);
  const [onUploadNumber, setOnUploadNumber] = useState(false);
  const [uploadNumber, setUploadNumber] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [newNumber, setNewNumber] = useState<number>();
  const [leadSource, setLeadSource] = useState(`${specificCampaign?.name} Sign up User`);
  const [name, setName] = useState('');
  const isLoading = useSelector(getLoaderValue);
  const { campaignId } = useParams();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showUploadedData, setShowUploadedData] = useState<boolean>(false);
  const [uploadedBulkData, setUploadedBulkData] = useState<any>();

  function handleToggleOffcanvas() {
    setShowOffcanvas(!showOffcanvas);
  }

  const { data: adminDetails } = useSelector((state: any) => state?.msme?.msmeUser);
  let initialState: any;
  if (specificCampaign.userMetaData) {
    initialState = Object.fromEntries(specificCampaign.userMetaData.map((i: any) => [i.label, '']));
  }
  const [formData, setFormData] = useState<any>(initialState);

  function handleInputChange(event: any, key: string) {
    setFormData((prevData: Object) => ({
      ...prevData,
      [key]: event
    }));
  }

  const renderMetaDataFields = () => {
    if (specificCampaign.userMetaData)
      return (
        <div className="mt-4">
          {specificCampaign.userMetaData.map((key: MetaDataProps) => (
            <PrimaryTextfield
              key={key.label}
              class="mt-2 w-100 number-input"
              fieldName={key.label ?? ''}
              type="text"
              required
              placeholder={`Enter the ${key.label}`}
              label={key.label}
              value={formData[key.label ?? '']}
              onChange={(e) => handleInputChange(e, key.label ?? '')}
            />
          ))}
        </div>
      );
  };

  async function handleBulkUpload() {
    const data = {
      file: selectedFile,
      msmeCampaignId: campaignId
    };
    console.log(data);
    try {
      const res = await dispatch(bulkUploadAction(data));
      if (res?.status == 200) {
        setUploadedBulkData(res.data);
        setShowUploadedData(true);
        const apiData = {
          campaignId: campaignId || 'abcd'
        };
        const response = await dispatch(bulkCallScheduleAction(apiData));
        dispatch({ type: LOADING_OFF });
        if (response?.status == 200) {
          toast.success('Bulk Calls Scheduled');
          setUploadFile(false);
        } else {
          toast.error('Call schedule failed');
          setUploadFile(false);
        }
      } else {
        toast.error('Failed');
        dispatch({ type: LOADING_OFF });
        setUploadFile(false);
      }
    } catch (e) {
      dispatch({ type: LOADING_OFF });
      console.log(e);
    }
  }

  function handleSubmit() {
    if (!selectedFile) {
      setErrorMsg('Please upload a file');
      return;
    } else {
      dispatch({ type: LOADING_ON });
      handleBulkUpload();
    }
  }

  async function handleNumberSubmit() {
    if (uploadNumber.length < 10) {
      toast.error('Number should have minimum 9 digits');
    } else if (leadSource === '') {
      toast.error('Please select a lead source');
    } else {
      dispatch({ type: LOADING_ON });
      const data = {
        msmeCampaignId: campaignId,
        userMobileNumber: uploadNumber,
        userName: name || '',
        leadSource: leadSource || ''
      };
      const dataToSend = { ...data, userMetaData: { ...formData } };
      try {
        const res = await dispatch(addSingleCallNumberAction(dataToSend));
        dispatch({ type: LOADING_OFF });
        if (res.status == 200) {
          toast.success('Number added');
          setOnUploadNumber(false);
          setUploadNumber('');
          setName('');
          setFormData(initialState);
          setShowOffcanvas(false);
        } else {
          toast.success('Failed');
        }
      } catch (e) {
        dispatch({ type: LOADING_OFF });
        console.log(e);
      }
    }
  }

  async function downloadSampleData() {
    try {
      const res = await dispatch(sampleBulkUploadDataAction(campaignId ?? ''));
      convertBinaryToXlsx(res, specificCampaign?.name ?? 'N/A');
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  }

  async function showUploadedDataFile() {
    convertBinaryToXlsx(uploadedBulkData, `call-upload-result ${specificCampaign?.name}`);
    setShowUploadedData(false);
  }

  function showBalance(amount: string) {
    let a = Number(amount);
    console.log(typeof a);
    let b = a.toFixed(2);
    return ' ₹' + b;
  }

  return (
    <div className=" mt-4 pb-4 ">
      <div className="d-flex justify-content-between flex-row flex-wrap-reverse">
        <div className="w-50">
          <Heading1>
            <span style={{ color: 'var(--primary)' }} className="text-capitalize">
              {specificCampaign?.name ?? 'N/A'}
            </span>
          </Heading1>
        </div>
        <div className="d-flex mb-4 mb-md-0 flex-wrap">
          {specificCampaign?.active && specificCampaign?.type != 'INCOMING' && (
            <div className="d-flex">
              <button
                className="btn secondary-btn btn-full d-flex align-items-center justify-content-center mx-1 h-fit-content"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightAddCall"
                aria-controls="offcanvasRight"
                onClick={handleToggleOffcanvas}
              >
                <Para fontWeight={FontWeight.MEDIUM}>Schedule Call</Para>
              </button>

              <SecondaryButton
                className="mx-1 h-fit-content"
                handleBtnClick={() => {
                  setUploadFile(true);
                }}
              >
                <Para fontWeight={FontWeight.MEDIUM}>Schedule Bulk Calls</Para>
              </SecondaryButton>
            </div>
          )}
          {adminDetails?.email?.endsWith('@voizo.ai') ? (
            <SecondaryButton
              className="mx-1 h-fit-content"
              handleBtnClick={() => {
                navigate(`/edit/${campaignId}`, {
                  state: {
                    userMetaData: specificCampaign?.userMetaData ?? 'N/A'
                  }
                });
              }}
            >
              <Para fontWeight={FontWeight.MEDIUM}>Update</Para>
            </SecondaryButton>
          ) : (
            <></>
          )}
          <PrimaryButton
            className="ms-1 h-fit-content"
            handleBtnClick={() => {
              navigate(`/edit-campaign-details/${campaignId}`, {
                state: {
                  msmeCampaignId: campaignId,
                  msmeName: specificCampaign?.name ?? 'N/A',
                  greetingMessagePrev: specificCampaign?.greetingMessage ?? '',
                  statusPrev: specificCampaign?.active,
                  dailyLimitPrev: specificCampaign?.maxDailyLimit ?? 'N/A',
                  ttsModel: specificCampaign?.ttsModel ?? 'N/A',
                  llmModelData: specificCampaign?.llmModel ?? 'N/A',
                  sttModelData: specificCampaign?.sttModel ?? 'N/A'
                }
              });
            }}
          >
            <Para fontWeight={FontWeight.MEDIUM}>Edit</Para>
          </PrimaryButton>
        </div>
      </div>

      <Para className="my-1">
        <>
          Campaign Type :
          <span className="fw-500" style={{ color: 'var(--primary)' }}>
            <> {specificCampaign?.type ?? 'N/A'}</>
          </span>
        </>
      </Para>
      <Para className="my-1 w-50">
        <>
          Greeting message :
          <span className="fw-500" style={{ color: 'var(--primary)' }}>
            <> {specificCampaign?.greetingMessage ?? 'N/A'}</>
          </span>
        </>
      </Para>
      <Para className="my-1">
        <>
          Status :
          <span className="fw-500" style={{ color: 'var(--primary)' }}>
            <> {specificCampaign?.active ? 'Active' : 'InActive'}</>
          </span>
        </>
      </Para>

      {specificCampaign?.type == 'OUTBOUND' ? (
        <Para className="my-1">
          <>
            Daily limit :
            <span className="fw-500" style={{ color: 'var(--primary)' }}>
              <> {specificCampaign?.maxDailyLimit ?? 'N/A'}</>
            </span>
          </>
        </Para>
      ) : (
        <Para className="my-1">
          <>
            Mobile Number :
            <span className="fw-500" style={{ color: 'var(--primary)' }}>
              <> {specificCampaign?.mobileNumber ?? 'N/A'}</>
            </span>
          </>
        </Para>
      )}
      <Para>
        <>
          Balance :
          <span className="fw-500" style={{ color: 'var(--primary)' }}>
            {showBalance(adminDetails?.msmeDetails?.budget ?? 'N?A')}
          </span>
        </>
      </Para>
      <AlertModal
        active={uploadFile}
        primaryBtnTitle={'Upload file'}
        isDisabled={selectedFile ? false : true}
        onPrimaryBtnClick={handleSubmit}
        onClose={() => {
          setUploadFile(false);
        }}
        isLoading={isLoading}
      >
        <div className="text-center">
          <UploadFile
            label="Click to upload"
            errorMessage={''}
            onFileUpload={(data) => {
              // handleUploadedFileData(data);
            }}
            onFileSelected={(file: any) => {
              setSelectedFile(file);
            }}
          />
          <Para>
            <>
              Click here to get{' '}
              <span className="clickable-text" onClick={downloadSampleData}>
                Sample
              </span>{' '}
              upload data
            </>
          </Para>
        </div>
      </AlertModal>

      <AlertModal
        active={showUploadedData}
        primaryBtnTitle="Show uploaded file"
        onPrimaryBtnClick={showUploadedDataFile}
        onClose={() => {
          setShowUploadedData(false);
        }}
      >
        <div className="text-center">
          <Para className="mb-4">Your data has been Successfully Uploaded! 🎉</Para>
        </div>
      </AlertModal>

      <div
        className={`offcanvas offcanvas-end ${
          !showOffcanvas ? 'd-none invisible' : 'show visible '
        }`}
        tabIndex={-1}
        id="offcanvasRightAddCall"
        aria-labelledby="offcanvasRightLabel"
        data-bs-backdrop="false"
        style={{ background: 'var(--backgroundMain)' }}
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Schedule Single Call
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            onClick={() => {
              setUploadNumber('');
              setName('');
              setFormData(initialState);
              setShowOffcanvas(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <form
            onSubmit={(e: any) => {
              e?.preventDefault();
              handleNumberSubmit();
            }}
          >
            <PrimaryTextfield
              class="mt-2 w-100 number-input"
              fieldName="name"
              type="text"
              placeholder="Enter the name"
              label="Name"
              value={name}
              required
              onChange={(e: any) => {
                setName(e);
              }}
            />
            <PrimaryTextfield
              class="mt-2 w-100 number-input"
              fieldName="number"
              type="number"
              placeholder="Enter phone number"
              label="Phone number"
              value={uploadNumber}
              required
              onChange={(e: any) => {
                setUploadNumber(e);
              }}
            />
            <SelectInput
              labelText="Lead source"
              optionsList={[
                `${specificCampaign?.name} Sign up User`,
                `${specificCampaign?.name} New Call Request`,
                `${specificCampaign?.name} Feedback Request`
              ]}
              setData={(e) => setLeadSource(e)}
              required
            ></SelectInput>

            {renderMetaDataFields()}
            <PrimaryButton
              className="w-100"
              handleBtnClick={() => {}}
              type={ButtonType.SUBMIT}
              isLoading={isLoading}
              loadingText="Please wait"
            >
              Submit
            </PrimaryButton>
          </form>
        </div>
      </div>
      <div className={`${showOffcanvas ? 'overlay-99 shows' : ''}`}></div>
    </div>
  );
}
