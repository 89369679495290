export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit'
}

export enum InterviewStatusType {
  INVITED = 'INVITED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED'
}

export enum LoaderType {
  TILE = 'TILE',
  CARD = 'CARD'
}

export enum InterviewUpdateType {
  REINVITE_INTERVIEW = 'REINVITE_INTERVIEW',
  RETRY_INTERVIEW = 'RETRY_INTERVIEW'
}

export enum CallStatusType {
  COMPLETED = 'COMPLETED',
  NOT_REACHABLE = 'NOT_REACHABLE',
  SCHEDULED = 'SCHEDULED',
  RESCHEDULED = 'RESCHEDULED',
  PENDING = 'PENDING',
  ATTEMPTED = 'ATTEMPTED',
  UNSUCCESSFULL = 'UNSUCCESSFULL',
  BUSY = 'BUSY',
  NO_ANSWER = 'NO-ANSWER',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED'
}

export enum DisconnectedReason {
  USER_DISCONNECTED = 'USER_DISCONNECTED',
  SYSTEM_DISCONNECTED = 'SYSTEM_DISCONNECTED',
  USER_DISCONNECTED_BEFORE_VOICEBOT = 'USER_DISCONNECTED_BEFORE_VOICEBOT'
}

export enum CampaignCallType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  BOTH = 'BOTH'
}
