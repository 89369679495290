import React, { Fragment, useEffect, useState } from 'react';
import './styles.css';
import {
  ArrowClockwise,
  CaretLeft,
  CaretRight,
  Export,
  Funnel,
  MagnifyingGlass,
  WarningCircle
} from 'phosphor-react';
import { Heading2, Para } from '../../../../components/atoms/typography/typography';
import OffcanvasRight from './OffCanvasRight';
import {
  convertBinaryToXlsx,
  convertISOToDate,
  convertTime,
  showAlert
} from '../../../../utils/utils';
import { useDispatch } from 'react-redux';
import {
  exportCallDetailsListAction,
  getCampaignCallsAction,
  retryCallsAction
} from '../../../../redux/states/msme/msme.actions';
import { useSelector } from 'react-redux';
import { PrimaryTextfield } from '../../../../components/molecules/textfield/textfield';
import { CallStatusType, LoaderType } from '../../../../models/ui.enum';
import { SkeletonLoader } from '../../../../components/organism/skeleton-loader/skeletonLoader';
import ReactPaginate from 'react-paginate';
import {
  PrimaryButton,
  SecondaryButton,
  StandardBtn
} from '../../../../components/molecules/buttons/buttons';
import { NoDataComponent } from '../../../../components/organism/no-data-component/noDataComponent';
import { toast } from 'react-toastify';
import { LOADING_OFF, LOADING_ON } from '../../../../redux/states/ui/ui.actions';
import { getLoaderValue } from '../../../../redux/states/ui/ui.services';
import { CallDetailDto } from '../../../../redux/states/msme/msme.dto';
import { AlertModal, InfoModal } from '../../../../components/molecules/modal/modal';
import NoInternet from '../../../../assets/no-internet.svg';
import { displayCallStatus } from '../../../../utils/ui';

interface CampaignCallsProps {
  campaignId: string;
  callListData: CallDetailDto[];
  totalPages: number;
  campaignName: string;
  campaignStatus: boolean;
  campaignType: string;
}

export default function CallDetailsTable({
  campaignId,
  callListData,
  totalPages,
  campaignName,
  campaignStatus,
  campaignType
}: CampaignCallsProps) {
  const [selectedCall, setSelectedCall] = useState<any>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const dispatch: any = useDispatch();
  const [searchNumber, setSearchNumber] = useState<number>();
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<number>(totalPages);
  const [searchCalls, setSearchCalls] = useState<CallDetailDto[]>(callListData);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<number[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [exportModal, setExportModal] = useState<boolean>(false);
  const [isRetry, setIsRetry] = useState(false);
  const [exportBtnLoading, setExportBtnLoading] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('ALL');
  const [isSelectedCall, setIsSelectedCall] = useState(false);

  const { data: campaignCalls, loading: loading } = useSelector(
    (state: any) => state?.msme?.campaignCalls
  );

  const isLoading = useSelector(getLoaderValue);

  const [checkedCheckboxes, setCheckedCheckboxes] = useState<number[]>([]);

  const handleCheckboxChange = (indexed: number, id: number) => {
    setCheckedCheckboxes((prev) =>
      prev.includes(indexed) ? prev.filter((i) => i !== indexed) : [...prev, indexed]
    );
    setSelectedPhoneNumbers((prev) =>
      prev.includes(id) ? prev.filter((checkboxId) => checkboxId !== id) : [...prev, id]
    );
  };

  async function getDataByNumber() {
    let numberString = searchNumber?.toString();
    let encodedNumberString = encodeURIComponent(numberString || '');
    const res = await dispatch(getCampaignCallsAction(campaignId, pageNumber, encodedNumberString));
    if (res?.data?.calls?.length != 0) {
      setSearchCalls(res?.data?.calls);
      setTotalNumberOfPages(res?.data?.totalPage);
    } else {
      setSearchCalls([]);
      setTotalNumberOfPages(res?.data?.totalPage);
    }
  }

  function dateAndTimeConversion(ISOFormat: any) {
    const ISO = new Date(ISOFormat);
    const time = convertTime(ISO);
    let date = convertISOToDate(ISO);
    return date + ' | ' + time;
  }

  const handleSelectAllChange = () => {
    setSelectAllChecked((prev) => !prev);

    if (selectAllChecked) {
      setCheckedCheckboxes([]);
      setSelectedPhoneNumbers([]);
    } else {
      const allIndices = searchCalls?.map((_: CallDetailDto, index: number) => index) ?? [];
      const allIds = searchCalls?.map((callDetails: CallDetailDto) => callDetails.userPhoneNumber);

      setCheckedCheckboxes(allIndices);
      setSelectedPhoneNumbers(allIds as number[]);
    }
  };

  async function callstatusType() {
    if (selectedStatus == 'ALL' && pageNumber == 1) {
      setTotalNumberOfPages(totalPages);
      return setSearchCalls(callListData);
    } else if (selectedStatus == 'ALL' && pageNumber != 1) {
      const res = await dispatch(getCampaignCallsAction(campaignId, pageNumber));
      setSearchCalls(res?.data?.calls);
      setTotalNumberOfPages(res?.data?.totalPage);
    } else {
      const res = await dispatch(
        getCampaignCallsAction(campaignId, pageNumber, null, selectedStatus)
      );
      setSearchCalls(res?.data?.calls);
      setTotalNumberOfPages(res?.data?.totalPage);
    }
  }

  useEffect(() => {
    callstatusType();
  }, [selectedStatus, pageNumber]);

  useEffect(() => {
    setSearchCalls(callListData);
  }, [callListData]);

  useEffect(() => {
    setTotalNumberOfPages(totalPages);
  }, [totalPages]);

  async function retryCalls() {
    dispatch({ type: LOADING_ON });
    const data = {
      campaignId: campaignId,
      userMobileNumbers: selectedPhoneNumbers
    };
    const res = await dispatch(retryCallsAction(data));
    if (res.status == 200) {
      dispatch({ type: LOADING_OFF });
      toast.success('Retry action initiated');
      setIsRetry(false);
      setCheckedCheckboxes([]);
      setSelectedPhoneNumbers([]);
    } else {
      dispatch({ type: LOADING_OFF });
      setIsRetry(false);
      toast.success('Failed');
    }
  }

  async function handleExportCallDetails() {
    setExportBtnLoading(true);
    try {
      const res = await dispatch(exportCallDetailsListAction(campaignId ?? ''));
      convertBinaryToXlsx(res, campaignName);
      console.log(res);
    } catch (e) {
      console.log(e);
    }
    setExportModal(false);
    setExportBtnLoading(false);
  }

  return (
    <Fragment>
      {callListData?.length != 0 && (
        <div className="call-details-table-container my-5">
          <div className="d-flex flex-wrap justify-content-between mx-4">
            <div className="d-flex flex-wrap">
              <PrimaryTextfield
                onChange={(e: any) => {
                  setSearchNumber(e);
                }}
                placeholder="Search by Number"
                value={searchNumber || ''}
                fieldName=""
                type="search"
                class="inputField mt-4"
                prefixIcon={<MagnifyingGlass size={26} />}
              />
              <PrimaryButton className=" mx-2 my-4" handleBtnClick={getDataByNumber}>
                Search
              </PrimaryButton>
            </div>

            <div className="d-flex flex-wrap">
              <SecondaryButton
                className="my-4"
                handleBtnClick={() => {
                  setExportModal(true);
                }}
              >
                <>
                  <Export size={18} className="me-2" />
                  Export
                </>
              </SecondaryButton>
              <div className="dropdown mt-4 ms-2">
                <button
                  className="btn drop-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="d-flex">
                    <Funnel size={20} className="me-2" />
                    <Para>
                      <>Status {selectedStatus != 'ALL' && '🟢'}</>
                    </Para>
                  </div>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus('ALL');
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      All
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus(CallStatusType.PENDING);
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      Pending
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus(CallStatusType.SCHEDULED);
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      Scheduled
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus(CallStatusType.ATTEMPTED);
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      Attempted
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus(CallStatusType.RESCHEDULED);
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      Rescheduled
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus(CallStatusType.NOT_REACHABLE);
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      Not Reachable
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus(CallStatusType.UNSUCCESSFULL);
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      Unsuccessfull
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedStatus(CallStatusType.COMPLETED);
                    }}
                  >
                    <div className="dropdown-item" role="button">
                      Completed
                    </div>
                  </li>
                </ul>
              </div>
              {campaignStatus && campaignType != 'INCOMING' && (
                <StandardBtn
                  className="mx-2 my-4"
                  isLoading={isLoading}
                  handleBtnClick={() => {
                    if (selectedPhoneNumbers?.length != 0) {
                      setIsRetry(true);
                    } else {
                      showAlert(dispatch, 'No contact selected');
                    }
                  }}
                >
                  <>
                    <ArrowClockwise size={16} weight="bold" className="me-2" />
                    <Para>Retry</Para>
                  </>
                </StandardBtn>
              )}
            </div>
          </div>
          {loading ? (
            <SkeletonLoader cardType={LoaderType.TILE} numTiles={4} />
          ) : searchCalls?.length != 0 ? (
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th scope="col" className="ps-4 py-3">
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          className="me-3"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                        <Para className=" text-break text-nowrap ps-2">CONTACT</Para>
                      </div>
                    </th>
                    <th scope="col" className="ps-5">
                      LAST CONTACTED
                    </th>
                    <th scope="col" className="ps-5">
                      STATUS
                    </th>
                    <th scope="col" className="ps-5">
                      CREATED TIME
                    </th>
                    <th scope="col" className="ps-5">
                      ATTEMPTS
                    </th>
                    <th scope="col" className="ps-5">
                      LEAD SOURCE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchCalls?.map((callDetails: CallDetailDto, index: number) => {
                    return (
                      <tr
                        onClick={() => {
                          setSelectedCall(callDetails);
                        }}
                        key={index}
                        style={callDetails?.status === 'Pending' ? { background: '#80808052' } : {}}
                        role={callDetails?.status === 'Pending' ? '' : 'button'}
                        className={callDetails?.status === 'Pending' ? '' : 'call-row'}
                        // data-bs-toggle={callDetails?.status === 'Pending' ? 'test' : 'offcanvas'}
                      >
                        <td className="ps-4">
                          <div className="d-flex text-nowrap">
                            <div className="w-100" style={{ wordBreak: 'break-all' }}>
                              <div className="custom-control custom-checkbox d-flex">
                                <input
                                  type="checkbox"
                                  className="custom-control-input me-3"
                                  id={`checkbox-${index}`}
                                  size={12}
                                  checked={checkedCheckboxes.includes(index)}
                                  onChange={() =>
                                    handleCheckboxChange(index, callDetails?.userPhoneNumber || 0)
                                  }
                                />
                                <label className="custom-control-label w-100">
                                  <div
                                    className={`navbar-toggler`}
                                    role="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    aria-controls="offcanvasRight"
                                    onClick={() => {
                                      setIsSelectedCall(true);
                                    }}
                                    // aria-label="Toggle navigation"
                                  >
                                    <Para className=" fw-bold">
                                      {callDetails.userPhoneNumber
                                        ? callDetails.userPhoneNumber
                                        : 'N/A'}
                                    </Para>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          className={`navbar-toggler`}
                          role="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          aria-label="Toggle navigation"
                          onClick={() => {
                            setIsSelectedCall(true);
                          }}
                        >
                          <Para className="text-nowrap ps-5">
                            {callDetails.lastAttemptedOn
                              ? dateAndTimeConversion(callDetails?.lastAttemptedOn)
                              : 'N/A'}
                          </Para>
                        </td>
                        <td
                          className={`navbar-toggler`}
                          role="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          aria-label="Toggle navigation"
                          onClick={() => {
                            setIsSelectedCall(true);
                          }}
                        >
                          <Para className="text-nowrap ps-5">
                            {callDetails.status ? displayCallStatus(callDetails.status) : 'N/A'}
                          </Para>
                        </td>
                        <td
                          className={`navbar-toggler`}
                          role="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          aria-label="Toggle navigation"
                          onClick={() => {
                            setIsSelectedCall(true);
                          }}
                        >
                          <Para className="text-nowrap ps-5">
                            {callDetails.createdTime
                              ? dateAndTimeConversion(callDetails?.createdTime)
                              : 'N/A'}
                          </Para>
                        </td>
                        <td
                          className={`navbar-toggler`}
                          role="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          aria-label="Toggle navigation"
                          onClick={() => {
                            setIsSelectedCall(true);
                          }}
                        >
                          <Para className="text-nowrap ps-5">
                            {callDetails.calls ? callDetails.calls.length : '0'}
                          </Para>
                        </td>
                        <td
                          className={`navbar-toggler`}
                          role="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          aria-label="Toggle navigation"
                          onClick={() => {
                            setIsSelectedCall(true);
                          }}
                        >
                          <Para className="text-nowrap ps-5">
                            {callDetails.leadSource ? callDetails.leadSource : 'N/A'}
                          </Para>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <NoDataComponent
              icon={<WarningCircle size={50} color="gray" />}
              text="No contact found"
            />
          )}
          <div className="d-flex justify-content-end mx-4 my-2">
            {totalNumberOfPages > 1 && (
              <ReactPaginate
                pageCount={totalNumberOfPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
                onPageChange={(e) => {
                  setPageNumber(e.selected + 1);
                }}
                forcePage={pageNumber - 1}
                className={'pagination'}
                pageClassName={'page-item'}
                activeClassName={'active'}
                previousLabel={<CaretLeft size={16} weight="bold" />}
                nextLabel={<CaretRight size={16} weight="bold" />}
                breakLabel={'....'}
              />
            )}
          </div>
        </div>
      )}
      <AlertModal
        active={isRetry}
        primaryBtnTitle={'Retry'}
        isLoading={isLoading}
        onPrimaryBtnClick={retryCalls}
        onClose={() => {
          setIsRetry(false);
        }}
      >
        <div className="text-center">
          <img src={NoInternet} className="mb-3" />
          <Heading2>Are you sure you want to retry?</Heading2>
        </div>
      </AlertModal>

      <InfoModal
        active={exportModal}
        onClose={() => {
          setExportModal(false);
        }}
      >
        <div className="text-center">Are you sure you want to export list of Call details?</div>
        <div className="d-flex justify-content-evenly mt-4">
          <PrimaryButton
            isLoading={exportBtnLoading}
            className="w-25"
            handleBtnClick={handleExportCallDetails}
          >
            Yes
          </PrimaryButton>
          <StandardBtn
            isLoading={exportBtnLoading}
            className="w-25"
            handleBtnClick={() => {
              setExportModal(false);
            }}
          >
            Cancel
          </StandardBtn>
        </div>
      </InfoModal>

      <OffcanvasRight
        selectedCall={selectedCall}
        updateSelectedCall={setIsSelectedCall}
        campaignStatus={campaignStatus}
        campaignType={campaignType}
      />
      <div className={`${isSelectedCall ? 'overlay-99 shows' : ''}`}></div>
    </Fragment>
  );
}
