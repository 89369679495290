import React from 'react';
import { Caption, FontWeight, Heading2 } from '../../atoms/typography/typography';
import { Para } from '../../atoms/typography/typography';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { StandardBtn } from '../../molecules/buttons/buttons';
import { CampaignCardProps } from '../../../redux/states/msme/msme.dto';
import { displayCallType } from '../../../utils/ui';

function CampaignCard({ name, _id, inActive, type }: CampaignCardProps) {
  const navigate = useNavigate();

  return (
    <div
      className="campaign-card p-4 position-relative"
      style={{ backgroundColor: inActive ? 'var(--lightestGray)' : '' }}
    >
      <div>
        <Heading2 className="text-capitalize">{name}</Heading2>
        <div className="status-data flex-wrap d-flex mt-2">{displayCallType(type)}</div>
      </div>

      <StandardBtn
        className="py-1 text-center container-fluid mt-3"
        handleBtnClick={() => {
          navigate(`/campaign-detail/${_id}`);
        }}
        btnTextColor="var(--primary)"
        btnSize="sm"
      >
        <Para fontWeight={FontWeight.MEDIUM}>View Campaign</Para>
      </StandardBtn>
    </div>
  );
}

export default CampaignCard;
