import axios from 'axios';
import { BASE_URL } from '../../../utils/config';
import { loadData, removeData } from '../handleData/localStorage';
import { toast } from 'react-toastify';

async function makeAPIRequest(
  method: any,
  url: string,
  data?: any,
  type?: string,
  responseType?: any
) {
  const token = loadData('token');
  const tokenBearer = token;
  const debugEmail = localStorage.getItem('debug-email');

  const config = {
    method,
    url: `${BASE_URL}/${url}`,
    headers: {
      'x-access-token': tokenBearer,
      'Content-Type': type != 'file' ? 'application/json' : 'multipart/form-data',
      'x-access-debug-email': debugEmail
    },
    data,
    responseType
  };
  return axios(config);
}

export function post(url: string, data: any, type?: string, responseType?: string) {
  delete axios.defaults.headers.common.Origin;
  return makeAPIRequest('POST', url, data, type, responseType)
    .then((res) => res)
    .catch((e) => e?.response?.data);
}

export function put(url: string, data: any) {
  return makeAPIRequest('PUT', url, data)
    .then((res) => res)
    .catch((e) => e?.response?.data);
}

export function patch(url: string, data: any) {
  return makeAPIRequest('PATCH', url, data)
    .then((res) => res?.data)
    .catch((e) => e?.response?.data);
}

export function get(url: string, data?: any, responseType?: string) {
  return makeAPIRequest('GET', url, data, '', responseType)
    .then((res) => {
      return res?.data;
    })
    .catch((e) => {
      if (e?.response.status === 401) {
        removeData('token');
        toast.error('Session Expired. Please Login again');
        window.location.reload();
      }
    });
}

export function delReq(url: string) {
  return makeAPIRequest('DELETE', url).then((res) => res?.data);
}
