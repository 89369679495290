import { CaretDown } from 'phosphor-react';
import React from 'react';
import './styles.css';

interface SelectInputProps {
  setData: (value: any) => void;
  labelText: string;
  optionsList?: any;
  required?: boolean;
  defaultValue?: number;
  className?: string;
}

function SelectInput(prop: SelectInputProps) {
  const { setData, labelText, optionsList, required, defaultValue, className } = prop;
  return (
    <div className={`${className} position-relative`}>
      <label className="mb-1">{labelText}</label>
      <select
        className={`form-select form-control select-input mb-4 `}
        aria-label="Default select example"
        onChange={(e) => {
          setData(e.target.value);
        }}
        defaultValue={optionsList[defaultValue ?? 0]}
        required={required}
      >
        {optionsList.map((optionValue: string, index: number) => (
          <option value={optionValue} key={index} selected={index == defaultValue}>
            {optionValue}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectInput;
