import React, { Fragment, useEffect, useState } from 'react';
import { FontWeight, Heading1, Para } from '../../components/atoms/typography/typography';
import { PrimaryButton, SecondaryButton } from '../../components/molecules/buttons/buttons';
import { WarningCircle } from 'phosphor-react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SkeletonLoader } from '../../components/organism/skeleton-loader/skeletonLoader';
import { LoaderType } from '../../models/ui.enum';
import { NoDataComponent } from '../../components/organism/no-data-component/noDataComponent';
import { getMsmeCampaignAction, getMsmeUserAction } from '../../redux/states/msme/msme.actions';
import { LOGO_BASE_URL } from '../../utils/config';
import { CampaignCardProps } from '../../redux/states/msme/msme.dto';
import CampaignCard from '../../components/organism/campaign-card/campaignCards';

export const Home = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { data: campaigns, loading: loading } = useSelector(
    (state: any) => state?.msme?.msmeCampaign
  );
  const { data: user } = useSelector((state: any) => state?.msme?.msmeUser);
  const logoUrl = `${LOGO_BASE_URL}/${user?.msmeDetails?.logo}`;

  const [activeTab, setActiveTab] = useState<string>('active');
  const [campaignCardsArray, setCampaignCardsArray] = useState<any>();

  function getFilteredCampaignCards(inActive: boolean) {
    const cards = campaigns?.filter((campaign: any) => {
      return campaign.active !== inActive;
    });
    setCampaignCardsArray(cards);
    if (inActive) setActiveTab('expired');
    else setActiveTab('active');
  }

  useEffect(() => {
    getFilteredCampaignCards(false);
  }, [campaigns]);

  useEffect(() => {
    dispatch(getMsmeCampaignAction());
  }, []);

  const { data: adminDetails } = useSelector((state: any) => state?.msme?.msmeUser);

  return (
    <Fragment>
      <div className="d-flex align-items-center justify-content-between flex-wrap px-lg-5 px-3 mt-5">
        <div className="">
          <div className="d-flex">
            {user?.msmeDetails?.logo ? (
              <img src={logoUrl} alt="User Logo" className="company-logo me-2" />
            ) : (
              <></>
            )}
            <Heading1 className="text-capitalize">{user?.msmeDetails?.name}</Heading1>
          </div>
          <Para textColor="var(--textGray)">You can check all your campaigns here</Para>
        </div>
        {adminDetails?.email?.endsWith('@voizo.ai') && (
          <PrimaryButton
            handleBtnClick={() => {
              navigate(`/create-campaign/${user?.msmeDetails?._id}`);
            }}
          >
            Create Campaign
          </PrimaryButton>
        )}
      </div>
      <div>
        <div className="p-lg-5 p-3">
          <div className="d-flex">
            <SecondaryButton
              className={`${activeTab === 'active' && 'active'} ms-4`}
              handleBtnClick={() => {
                getFilteredCampaignCards(false);
              }}
            >
              <Para fontWeight={FontWeight.MEDIUM}>Active</Para>
            </SecondaryButton>
            <SecondaryButton
              className={`${activeTab === 'expired' && 'active'} ms-4`}
              handleBtnClick={() => {
                getFilteredCampaignCards(true);
              }}
            >
              <Para fontWeight={FontWeight.MEDIUM}>Inactive</Para>
            </SecondaryButton>
          </div>
          {loading ? (
            <SkeletonLoader cardType={LoaderType.CARD} />
          ) : campaignCardsArray?.length ? (
            <div className="row p-4">
              {campaignCardsArray?.map((campaign: CampaignCardProps, index: number) => {
                return (
                  <div className="col-xl-4 col-lg-6 mb-3" key={index}>
                    <CampaignCard
                      key={index}
                      name={campaign.name}
                      _id={campaign?._id}
                      inActive={campaign?.inActive}
                      type={campaign?.type}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <NoDataComponent
              icon={<WarningCircle size={50} color="gray" />}
              text="No campaign found"
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
