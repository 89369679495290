import { combineReducers } from 'redux';
import * as Types from './msme.constant';

function makeRequestReducer(actionType: any, defaultData = [], state = {}) {
  const initialState = {
    data: defaultData,
    error: false,
    loading: false,
    errorMsg: '',
    ...state
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/default-param-last
  return (state = initialState, action: any) => {
    switch (action?.type) {
      case `${actionType}_REQUEST`:
        console.log('requested');
        return {
          ...state,
          loading: true,
          error: false
        };
      case `${actionType}_SUCCESS`:
        console.log('success');
        return {
          ...state,
          loading: false,
          error: false,
          data: action?.payload?.data,
          errorMsg: ''
        };
      case `${actionType}_FAILED`:
        return {
          ...state,
          loading: false,
          error: true,
          data: defaultData,
          errorMsg: action?.payload?.error
        };
      case `${actionType}_RESET`:
        return state;
      default:
        return state;
    }
  };
}

export default combineReducers({
  msmeUser: makeRequestReducer(Types.GET_MSME_USER, []),
  msmeCampaign: makeRequestReducer(Types.GET_MSME_CAMPAIGNS, []),
  campaignCalls: makeRequestReducer(Types.GET_CAMPAIGN_CALLS, []),
  campaignSummary: makeRequestReducer(Types.GET_CAMPAIGN_SUMMARY, []),
  campaignConversion: makeRequestReducer(Types.GET_CAMPAIGN_CONVERSION, []),
  specificCampaignDetail: makeRequestReducer(Types.GET_SPECIFIC_CAMPAIGN_DETAIL, []),
  callRecording: makeRequestReducer(Types.GET_CALL_RECORDING, []),
  instructions: makeRequestReducer(Types.GET_INSTRUCTIONS, []),
  exportCallDetailsList: makeRequestReducer(Types.EXPORT_CALL_DETAILS, []),
  sampleBulkUploadData: makeRequestReducer(Types.BULK_CALL_SCHEDULE, []),
  createMsmeCampaign: makeRequestReducer(Types.CREATE_MSME_CAMPAIGN, []),
  sampleAudio: makeRequestReducer(Types.GET_SAMPLE_AUDIO, []),
  modelConfigurations: makeRequestReducer(Types.GET_MODEL_CONFIGURATIONS, [])
});
