import { Dispatch } from 'redux';
import { handleFailure, handleRequest, handleSuccess } from '../../services/handleData/handleData';
import {
  addSingleCallNumberService,
  bulkCallScheduleService,
  bulkUploadService,
  createMsmeCampaignServicee,
  exportCallDetailsListService,
  getCallRecordingService,
  getCampaignCallsService,
  getCampaignConversionService,
  getCampaignSummaryService,
  getInstructionsService,
  getModelConfigurationsService,
  getMsmeCampaignsService,
  getMsmeUserService,
  getSpecificCampaignDetailService,
  postSampleAudioService,
  retryCallsService,
  sampleBulkUploadDataService,
  updateInstructionsService,
  updateSpecificCampaignDetailService
} from './msme.services';
import {
  ADD_SINGLE_CALL_NUMBER_FAILED,
  ADD_SINGLE_CALL_NUMBER_REQUEST,
  ADD_SINGLE_CALL_NUMBER_SUCCESS,
  BULK_CALL_SCHEDULE_FAILED,
  BULK_CALL_SCHEDULE_REQUEST,
  BULK_CALL_SCHEDULE_SUCCESS,
  BULK_UPLOAD_FAILED,
  BULK_UPLOAD_REQUEST,
  BULK_UPLOAD_SUCCESS,
  CREATE_MSME_CAMPAIGN_FAILED,
  CREATE_MSME_CAMPAIGN_REQUEST,
  CREATE_MSME_CAMPAIGN_SUCCESS,
  EXPORT_CALL_DETAILS_FAILED,
  EXPORT_CALL_DETAILS_REQUEST,
  EXPORT_CALL_DETAILS_SUCCESS,
  GET_CALL_RECORDING_FAILED,
  GET_CALL_RECORDING_REQUEST,
  GET_CALL_RECORDING_SUCCESS,
  GET_CAMPAIGN_CALLS_FAILED,
  GET_CAMPAIGN_CALLS_REQUEST,
  GET_CAMPAIGN_CALLS_SUCCESS,
  GET_CAMPAIGN_CONVERSION_FAILED,
  GET_CAMPAIGN_CONVERSION_REQUEST,
  GET_CAMPAIGN_CONVERSION_SUCCESS,
  GET_CAMPAIGN_SUMMARY_FAILED,
  GET_CAMPAIGN_SUMMARY_REQUEST,
  GET_CAMPAIGN_SUMMARY_SUCCESS,
  GET_INSTRUCTIONS_FAILED,
  GET_INSTRUCTIONS_REQUEST,
  GET_INSTRUCTIONS_SUCCESS,
  GET_MODEL_CONFIGURATIONS_FAILED,
  GET_MODEL_CONFIGURATIONS_REQUEST,
  GET_MODEL_CONFIGURATIONS_SUCCESS,
  GET_MSME_CAMPAIGNS_FAILED,
  GET_MSME_CAMPAIGNS_REQUEST,
  GET_MSME_CAMPAIGNS_SUCCESS,
  GET_MSME_USER_FAILED,
  GET_MSME_USER_REQUEST,
  GET_MSME_USER_SUCCESS,
  GET_SAMPLE_AUDIO_FAILED,
  GET_SAMPLE_AUDIO_REQUEST,
  GET_SAMPLE_AUDIO_SUCCESS,
  GET_SPECIFIC_CAMPAIGN_DETAIL_FAILED,
  GET_SPECIFIC_CAMPAIGN_DETAIL_REQUEST,
  GET_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS,
  RETRY_CALLS_FAILED,
  RETRY_CALLS_REQUEST,
  RETRY_CALLS_SUCCESS,
  SAMPLE_BULK_UPLOAD_DATA_FAILED,
  SAMPLE_BULK_UPLOAD_DATA_REQUEST,
  SAMPLE_BULK_UPLOAD_DATA_SUCCESS,
  UPDATE_INSTRUCTIONS_FAILED,
  UPDATE_INSTRUCTIONS_REQUEST,
  UPDATE_INSTRUCTIONS_SUCCESS,
  UPDATE_SPECIFIC_CAMPAIGN_DETAIL_FAILED,
  UPDATE_SPECIFIC_CAMPAIGN_DETAIL_REQUEST,
  UPDATE_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS
} from './msme.constant';

export const getMsmeUserAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_MSME_USER_REQUEST));
  try {
    const res = await getMsmeUserService();
    dispatch(handleSuccess(GET_MSME_USER_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_MSME_USER_FAILED, ex?.message));
    return ex;
  }
};

export const getMsmeCampaignAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_MSME_CAMPAIGNS_REQUEST));
  try {
    const res = await getMsmeCampaignsService();
    dispatch(handleSuccess(GET_MSME_CAMPAIGNS_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_MSME_CAMPAIGNS_FAILED, ex?.message));
    return ex;
  }
};

export const getCampaignCallsAction =
  (campaignId: string, pageNumber?: number, mobileNumber?: string | null, status?: string) =>
  async (dispatch: Dispatch) => {
    let urlParams;
    if (mobileNumber) {
      urlParams = `?id=${campaignId}&pageNumber=${pageNumber}&mobileNumber=${mobileNumber}`;
    } else if (status) {
      urlParams = `?id=${campaignId}&pageNumber=${pageNumber}&status=${status ?? ''}`;
    } else {
      urlParams = `?id=${campaignId}&pageNumber=${pageNumber}`;
    }
    dispatch(handleRequest(GET_CAMPAIGN_CALLS_REQUEST));
    try {
      const res = await getCampaignCallsService(urlParams);
      dispatch(handleSuccess(GET_CAMPAIGN_CALLS_SUCCESS, res?.data));
      return res;
    } catch (ex: any) {
      dispatch(handleFailure(GET_CAMPAIGN_CALLS_FAILED, ex?.message));
      return ex;
    }
  };

export const getSpecificCampaignDetailAction =
  (campaignId: string) => async (dispatch: Dispatch) => {
    const urlParams = `?id=${campaignId}`;
    dispatch(handleRequest(GET_SPECIFIC_CAMPAIGN_DETAIL_REQUEST));
    try {
      const res = await getSpecificCampaignDetailService(urlParams);
      dispatch(handleSuccess(GET_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS, res?.data));
      return res;
    } catch (ex: any) {
      dispatch(handleFailure(GET_SPECIFIC_CAMPAIGN_DETAIL_FAILED, ex?.message));
      return ex;
    }
  };

export const getCampaignSummaryAction =
  (campaignId: string, dateTo: any, dateFrom: any) => async (dispatch: Dispatch) => {
    const urlParams = `?id=${campaignId}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    dispatch(handleRequest(GET_CAMPAIGN_SUMMARY_REQUEST));
    try {
      const res = await getCampaignSummaryService(urlParams);
      dispatch(handleSuccess(GET_CAMPAIGN_SUMMARY_SUCCESS, res?.data));
      return res;
    } catch (ex: any) {
      dispatch(handleFailure(GET_CAMPAIGN_SUMMARY_FAILED, ex?.message));
      return ex;
    }
  };

export const getCampaignConversionAction =
  (campaignId: string, chartType: any) => async (dispatch: Dispatch) => {
    const urlParams = `?id=${campaignId}&chartType=${chartType}`;
    dispatch(handleRequest(GET_CAMPAIGN_CONVERSION_REQUEST));
    try {
      const res = await getCampaignConversionService(urlParams);
      dispatch(handleSuccess(GET_CAMPAIGN_CONVERSION_SUCCESS, res?.data));
      return res;
    } catch (ex: any) {
      dispatch(handleFailure(GET_CAMPAIGN_CONVERSION_FAILED, ex?.message));
      return ex;
    }
  };

export const bulkUploadAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(BULK_UPLOAD_REQUEST));
  try {
    const res = await bulkUploadService(data);
    dispatch(handleSuccess(BULK_UPLOAD_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(BULK_UPLOAD_FAILED, ex?.message));
  }
};

export const updateSpecificCampaignDetailAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(UPDATE_SPECIFIC_CAMPAIGN_DETAIL_REQUEST));
  try {
    const res = await updateSpecificCampaignDetailService(data);
    dispatch(handleSuccess(UPDATE_SPECIFIC_CAMPAIGN_DETAIL_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(UPDATE_SPECIFIC_CAMPAIGN_DETAIL_FAILED, ex?.message));
    return ex;
  }
};

export const getCallRecordingAction = (recordingKey: string) => async (dispatch: Dispatch) => {
  const urlParams = `?recordingKey=${recordingKey}`;
  dispatch(handleRequest(GET_CALL_RECORDING_REQUEST));
  try {
    const res = await getCallRecordingService(urlParams);
    dispatch(handleSuccess(GET_CALL_RECORDING_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_CALL_RECORDING_FAILED, ex?.message));
    return ex;
  }
};

export const addSingleCallNumberAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(ADD_SINGLE_CALL_NUMBER_REQUEST));
  try {
    const res = await addSingleCallNumberService(data);
    dispatch(handleSuccess(ADD_SINGLE_CALL_NUMBER_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(ADD_SINGLE_CALL_NUMBER_FAILED, ex?.message));
  }
};

export const getInstructionsAction = (campaignId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?id=${campaignId}`;
  dispatch(handleRequest(GET_INSTRUCTIONS_REQUEST));
  try {
    const res = await getInstructionsService(urlParams);
    dispatch(handleSuccess(GET_INSTRUCTIONS_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_INSTRUCTIONS_FAILED, ex?.message));
    return ex;
  }
};

export const updateInstructionsAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(UPDATE_INSTRUCTIONS_REQUEST));
  try {
    const res = await updateInstructionsService(data);
    dispatch(handleSuccess(UPDATE_INSTRUCTIONS_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(UPDATE_INSTRUCTIONS_FAILED, ex?.message));
  }
};

export const retryCallsAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(RETRY_CALLS_REQUEST));
  try {
    const res = await retryCallsService(data);
    dispatch(handleSuccess(RETRY_CALLS_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(RETRY_CALLS_FAILED, ex?.message));
  }
};

export const bulkCallScheduleAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(BULK_CALL_SCHEDULE_REQUEST));
  try {
    const res = await bulkCallScheduleService(data);
    dispatch(handleSuccess(BULK_CALL_SCHEDULE_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(BULK_CALL_SCHEDULE_FAILED, ex?.message));
  }
};

export const exportCallDetailsListAction = (campaignId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?campaignId=${campaignId}`;
  dispatch(handleRequest(EXPORT_CALL_DETAILS_REQUEST));
  try {
    const res = await exportCallDetailsListService(urlParams);
    dispatch(handleSuccess(EXPORT_CALL_DETAILS_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(EXPORT_CALL_DETAILS_FAILED, ex?.message));
    return ex;
  }
};

export const sampleBulkUploadDataAction = (campaignId: string) => async (dispatch: Dispatch) => {
  const urlParams = `?campaignId=${campaignId}`;
  dispatch(handleRequest(SAMPLE_BULK_UPLOAD_DATA_REQUEST));
  try {
    const res = await sampleBulkUploadDataService(urlParams);
    dispatch(handleSuccess(SAMPLE_BULK_UPLOAD_DATA_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(SAMPLE_BULK_UPLOAD_DATA_FAILED, ex?.message));
    return ex;
  }
};

export const createMsmeCampaignAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(CREATE_MSME_CAMPAIGN_REQUEST));
  try {
    const res = await createMsmeCampaignServicee(data);
    dispatch(handleSuccess(CREATE_MSME_CAMPAIGN_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(CREATE_MSME_CAMPAIGN_FAILED, ex?.message));
  }
};

export const sampleAudioDataAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_SAMPLE_AUDIO_REQUEST));
  try {
    const res = await postSampleAudioService(data);
    dispatch(handleSuccess(GET_SAMPLE_AUDIO_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_SAMPLE_AUDIO_FAILED, ex?.message));
  }
};

export const getModelConfigurationsAction = () => async (dispatch: Dispatch) => {
  dispatch(handleRequest(GET_MODEL_CONFIGURATIONS_REQUEST));
  try {
    const res = await getModelConfigurationsService();
    dispatch(handleSuccess(GET_MODEL_CONFIGURATIONS_SUCCESS, res?.data));
    return res;
  } catch (ex: any) {
    dispatch(handleFailure(GET_MODEL_CONFIGURATIONS_FAILED, ex?.message));
  }
};
