import React, { useState } from 'react';
import './style.css';

interface TextFieldProps {
  label?: string;
  error?: string;
  value: string | number;
  disabled?: boolean;
  placeholder?: string;
  fieldName: string;
  onChange: (value: string) => void;
  type: string;
  class?: string;
  labelClass?: string;
  info?: string;
  required?: boolean;
  min?: string;
  max?: string;
  smallBottomMargin?: boolean;
  suffixIcon?: JSX.Element;
  onSuffixIconClick?: () => void;
  prefixIcon?: JSX.Element;
  preventDefault?: boolean;
}

export function PrimaryTextfield(props: TextFieldProps) {
  function handleFocus() {
    const element = document.getElementById(props.fieldName);
    if (element) {
      element.scrollIntoView();
    }
  }

  function handleKeydown(e: any) {
    if (props.preventDefault) {
      e?.preventDefault();
    }
  }
  return (
    <div className={`form-group ${props.smallBottomMargin ? 'mb-1' : 'mb-4'}`}>
      <div className="text-left mb-1">
        {props?.label && (
          <label
            htmlFor={props.fieldName ?? 'syncId'}
            className={`normal-text font-color-gray  ${props.labelClass}`}
          >
            {props?.label}
          </label>
        )}
      </div>
      <div className="position-relative">
        <div className="position-relative">
          {props.prefixIcon && <div className="prefix-icon">{props.prefixIcon}</div>}
          <input
            type={props.type}
            id={props.fieldName ?? 'syncId'}
            className={`form-control primary-input ${props.class} ${
              props.suffixIcon && 'right-padding'
            } `}
            placeholder={props.placeholder ? props.placeholder : ''}
            onChange={(e) => props.onChange(e.target.value)}
            onKeyDown={handleKeydown}
            value={props.value}
            disabled={props.disabled ? props.disabled : false}
            onFocus={handleFocus}
            required={props.required}
            min={props.min}
            max={props.max}
            autoComplete="off"
          />
          {props.suffixIcon && (
            <div
              className="suffix-icon"
              onClick={() => {
                if (props.onSuffixIconClick) props?.onSuffixIconClick();
              }}
              role={props.onSuffixIconClick ? 'button' : ''}
            >
              {props?.suffixIcon}
            </div>
          )}
        </div>

        {props.error && props.error.trim() !== '' && (
          <div className={'text-danger mt-1 text-left small'}>{props.error}</div>
        )}
      </div>
      {props.info && <p className="text-left mt-1 small font-color-gray">{props.info}</p>}
    </div>
  );
}
