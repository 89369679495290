import { removeData } from '../redux/services/handleData/localStorage';
import {
  ALERT_MODAL_HEADING,
  ALERT_MODAL_ON,
  ALERT_MODAL_PARA
} from '../redux/states/ui/ui.actions';

export function onLogout() {
  removeData('token');
  window.location.reload();
}

export function getNameInitials(fullName: string): string {
  if (!fullName) {
    return '';
  }
  const words = fullName?.trim().split(' ');
  const initials = words
    ?.slice(0, 2)
    ?.map((word) => word.charAt(0).toUpperCase())
    .join('');
  return initials;
}

export function showAlert(dispatch: any, title: string, description?: string) {
  dispatch({ type: ALERT_MODAL_ON });
  dispatch({ type: ALERT_MODAL_HEADING, payload: title });
  dispatch({ type: ALERT_MODAL_PARA, payload: description });
}

export function formatDate(inputDateStr: string) {
  const inputDate = new Date(inputDateStr);

  // Define options for formatting the date
  const options: any = { year: 'numeric', month: 'short', day: 'numeric' };

  // Convert the date to the desired format
  const formattedDate = inputDate.toLocaleDateString('en-US', options);

  return formattedDate;
}

export function displayDuration(a: number) {
  if (!a) return 'NA';
  let seconds = (a % 60).toString().padStart(2, '0');
  let minutes = Math.floor((a % 3600) / 60)
    .toString()
    .padStart(2, '0');
  let hours = Math.floor(a / 3600)
    .toString()
    .padStart(2, '0');
  return hours + ':' + minutes + ':' + seconds;
}

export function base64toFile(base64Data: any, fileName: any, fileType?: string) {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const file = new File(byteArrays, fileName, {
    type: fileType == 'image' ? 'image/png' : 'application/octet-stream'
  });
  return file;
}

export function playAudio(audioUrl: string) {
  const audio = new Audio(audioUrl);

  audio
    .play()
    .then(() => {})
    .catch((error) => {
      console.error('Error playing audio:', error);
    });
}

function stripDataUrlScheme(base64Data: string): string {
  const base64DataPattern = /^data:[a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+(;[a-zA-Z0-9-.=]+)?;base64,/;
  // Remove the matched Data URL scheme part, leaving just the base64-encoded data
  const base64 = base64Data.replace(base64DataPattern, '');

  return base64;
}

export function base64toBlob(base64Data: any, contentType: any) {
  const cleanBase64Data = stripDataUrlScheme(base64Data);
  console.log(cleanBase64Data);
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(cleanBase64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function convertISOToDate(str: any) {
  var date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join('-');
}

function convertTo12HourFormat(hours: any) {
  return hours % 12 === 0 ? 12 : hours % 12;
}

export function convertTime(str: any) {
  const dateObject = new Date(str);

  const hours = dateObject.getHours();
  const minutes = ('0' + dateObject.getMinutes()).slice(-2);
  // const seconds = dateObject.getSeconds();
  const period = hours < 12 ? ' AM' : ' PM';
  const hours12 = convertTo12HourFormat(hours);
  return [hours12, minutes].join(':') + [period];
}

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

// Helper function to convert base64 string to ArrayBuffer
export function base64ToArrayBuffer(base64: string) {
  const base64DataPattern = /^data:[a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+(;[a-zA-Z0-9-.=]+)?;base64,/;
  // Remove the matched Data URL scheme part, leaving just the base64-encoded data
  const base64Clean = base64.replace(base64DataPattern, '');

  const binaryString = window.atob(base64Clean);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function replaceDoubleQuotesWithSingleQuotes(str: string) {
  return str.replace(/"/g, "'");
}

export function convertBinaryToXlsx(binaryData: any, fileName?: string) {
  if (!binaryData) return;
  const url = window.URL.createObjectURL(new Blob([binaryData]));
  const link = document.createElement('a');
  link.href = url;
  const getDate = new Date(Date.now()).toISOString();
  const setDate = convertISOToDate(getDate);
  const setTime = convertTime(getDate);
  link.setAttribute('download', `${fileName} ${setDate} ${setTime}.xlsx`);
  document.body.appendChild(link);
  link.click();
}
