import { CloudArrowUp, XCircle } from 'phosphor-react';
import React, { useState } from 'react';
import { Caption, FontWeight, Para } from '../../atoms/typography/typography';
import { SecondaryButton } from '../../molecules/buttons/buttons';
import './styles.css';

interface UploadFileProps {
  label: string | number;
  onFileUpload: (value: any) => void;
  onFileSelected: any;
  errorMessage: string;
}

function UploadFile(prop: UploadFileProps) {
  const { label, onFileUpload, onFileSelected, errorMessage } = prop;
  const [selectedFile, setSelectedFile] = useState<any>(null);

  function onOpenPDF() {
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      window.open(fileURL, '_blank');
    }
  }

  function handleFileUpload(e: any) {
    try {
      const selected = e.target.files[0];
      if (selected?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setSelectedFile(selected);
        const formData = new FormData();
        formData.append('file', selected);
        onFileUpload(formData);
        onFileSelected(selected);
      } else {
        alert('Please choose a valid file.');
      }
    } catch (err) {}
  }

  return (
    <div>
      <div>
        {!selectedFile && (
          <div role="button">
            <label className="upload-file" htmlFor="file-upload">
              <CloudArrowUp size={32} weight="bold" />
              <Para fontWeight={FontWeight.SEMIBOLD} textColor="var(--primary)" className="my-3">
                {label}
              </Para>

              <div>
                <Caption textColor="var(--textGray)" className="mb-1">
                  * Only xlsx files are compatible for upload
                </Caption>

                <Caption textColor="var(--textGray)" className="mb-1">
                  * Please ensure that the file size does not exceed 5 MB
                </Caption>
              </div>
            </label>
            <input
              type="file"
              id="file-upload"
              className="invisible position-fixed"
              accept=".xlsx"
              onChange={(e) => {
                handleFileUpload(e);
              }}
            />
            <p className="text-danger text-left small mt-1">{errorMessage}</p>
          </div>
        )}

        {selectedFile && (
          <div className="upload-file mb-4 d-flex flex-column position-relative">
            <div
              className="cancel-button"
              onClick={() => {
                setSelectedFile(null);
                onFileSelected(null);
              }}
            >
              <XCircle size={32} />
            </div>
            <p>Selected File: {selectedFile?.name}</p>
            <Para>
              <>
                Click here to check{' '}
                <span className="clickable-text" onClick={onOpenPDF}>
                  selected
                </span>{' '}
                file.
              </>
            </Para>
          </div>
        )}
      </div>
    </div>
  );
}

export default UploadFile;
